import React, { useState } from "react";
import { Image, Row, Col, Container } from "react-bootstrap";

const PageLoaderFull = (props) => {
  return (
    <>
      <div className="page-loader-full-sec">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={6}>
              <div className="page-loader-full-box">
                <Image
                  className="page-loader-full-img"
                  src={window.location.origin + "/img/page-loader.gif"}
                  type="image/png"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PageLoaderFull;
