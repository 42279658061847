import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Dropdown,
  Image,
  Row,
  Tab,
  Nav,
  Col,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { userWalletStart, withdrawalListStart } from "../../store/slices/WithdrawSlice";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Field, Form as FORM, Formik } from "formik";
import NoDataFound from "../Helper/NoDataFound";
import Pagination from "../Helper/Pagination";
import { useTranslation } from "react-multi-lang";

const INRPayoutTransactionsIndex = (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation("payout_transactions");
  const [searchParams] = useSearchParams();
  const withdrawalList = useSelector(state => state.withdraw.withdrawalList);
  const userWallet = useSelector(state => state.withdraw.userWallet);
  const profile = useSelector(state => state.admin.profile);
  const [requestPayout, setRequestPayout] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [filter, setFilter] = useState({
    search_key: searchParams.get("search_key")
      ? searchParams.get("search_key")
      : "",
    status: "",
  });
  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );
  const [settlementCancelModal, setSettlementCancelModal] = useState(false);

  const closeSettlementCancelModal = () => {
    setSettlementCancelModal(false);
  };


  const closeRequestPayoutModal = () => {
    setRequestPayout(false);
  };

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(userWalletStart());
    }
  }, []);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(
        withdrawalListStart({
          ...filter,
          skip: 12 * (page - 1),
          take: 12,
        })
      );
    }
  }, [profile, filter, page]);

  const getStatusClass = (status) => {
    switch (status) {
      case 0:
        return "payment-initiated";
      case 1:
        return "payment-paid";
      case 2:
        return "payment-onhold";
      case 3:
        return "payment-rejected";
      case 4:
        return "payment-cancelled";
      default:
        return "payment-initiated";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 0:
        return "#5C9EEB";
      case 1:
        return "#197E23";
      case 2:
        return "#FFCE22";
      case 3:
        return "#FF6A6A";
      case 4:
        return "#FF8A00";
      default:
        return "#5C9EEB";
    }
  };

  const handlePageClick = ({ selected }) => {
    let params = searchParams.get("search_key")
      ? `search_key=${searchParams.get("search_key")}&`
      : "";
    navigate(`/settlement?${params}page=${selected + 1}`);
  };

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
      setFilter({
        search_key: searchParams.get("search_key")
          ? searchParams.get("search_key")
          : "",
      });
    }
    setSkipRender(false);
  }, [searchParams.get("page"), searchParams.get("search_key")]);

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
    }
    setSkipRender(false);
  }, [searchParams.get("page")]);

  return (
    <>
      <div className="settlement-sec">
        <div className="settlement-top-sec">
          <h3>{t("heading")}</h3>
        </div>
        <div className="settlement-header-sec">
          <div className="settlement-header-box">
            {userWallet.loading ? (
              [...Array(1)].map((i, key) => <Skeleton key={key} height={100} />)
            ) : Object.keys(userWallet.data).length > 0 &&
              Object.keys(userWallet.data.user_wallet).length > 0 ? (
              <React.Fragment>
                <div className="settlement-header-card">
                  <div className="settlement-header-icon-sec">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="35"
                      height="35"
                      fill="none"
                      viewBox="0 0 34 34"
                    >
                      <path
                        fill="#2F8C86"
                        fillOpacity="0.45"
                        d="M22.808 11.294l1.838 1.114a.997.997 0 101.033-1.706l-.177-.108.278-.036a.997.997 0 10-.256-1.978s-3.119.465-3.247.504c.09.848.275 1.57.531 2.21zM24.117 16.422c-1.416-1.468-2.866-2.974-3.527-5.483H13.41c-.66 2.509-2.11 4.015-3.526 5.483-1.805 1.732-3.385 4.177-3.385 7.137.56 13.927 20.45 13.915 21.004 0 0-2.96-1.58-5.405-3.385-7.137zm-6.09 11.669v.095a.998.998 0 01-1.995 0v-.094a3.048 3.048 0 01-1.815-1.697.998.998 0 011.837-.777 1.057 1.057 0 10.976-1.47 3.056 3.056 0 01-3.053-3.052c0-1.334.86-2.47 2.055-2.885v-.146a.997.997 0 111.995 0v.144c.417.144.802.379 1.127.694a.997.997 0 11-1.389 1.433 1.059 1.059 0 10-.736 1.818 3.056 3.056 0 013.053 3.052c0 1.334-.86 2.47-2.055 2.885zM22.785 2.937c-.45-1.112-1.474-1.795-2.742-1.827h-.025c-.213 0-.423.022-.627.065C18.88.384 17.938-.007 17 0c-.925.007-1.845.402-2.342 1.183a2.9 2.9 0 00-.697-.078c-1.255.01-2.311.722-2.756 1.86-.485 1.242-.145 2.7.846 3.63.741.694 1.33 1.763 1.599 2.35h6.718a8.33 8.33 0 011.618-2.378c.976-.99 1.29-2.415.799-3.63z"
                      ></path>
                    </svg>
                  </div>
                  <div className="settlement-header-info-sec">
                    <p>{t("total_settlement_amount")}</p>
                    <h3>{userWallet.data.user_wallet.total}</h3>
                  </div>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </div>
        <div className="settlement-body-sec">
          <div className="settlement-title-sec">
            <h3>{t("transactions")}</h3>
            {/* <div className="header-search">
              <Formik
                initialValues={{
                  search_key: searchParams.get("search_key")
                    ? searchParams.get("search_key")
                    : "",
                }}
                onSubmit={(val) => {
                  setFilter({
                    ...filter,
                    search_key: val.search_key,
                  });
                  navigate(`/settlement?search_key=${val.search_key}`);
                }}
              >
                {({ values, setFieldValue, resetForm }) => (
                  <FORM className="form">
                    <InputGroup className="mb-0">
                      <Field
                        placeholder="Search"
                        type="text"
                        className="form-control"
                        name="search_key"
                      />
                      <InputGroup.Text id="basic-addon2">
                        {searchParams.get("search_key") && (
                          <button
                            type="button"
                            className="search-btn"
                            onClick={() => {
                              setFieldValue("search_key", "");
                              setFilter({
                                ...filter,
                                search_key: "",
                              });
                              navigate(`/settlement`);
                            }}
                          >
                            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                          </button>
                        )}
                      </InputGroup.Text>
                      <InputGroup.Text id="basic-addon2">
                        <button className="search-btn" type="submit">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="#3E4351"
                            x="0"
                            y="0"
                            enableBackground="new 0 0 513.749 513.749"
                            version="1.1"
                            viewBox="0 0 513.749 513.749"
                            xmlSpace="preserve"
                          >
                            <path d="M504.352 459.061l-99.435-99.477c74.402-99.427 54.115-240.344-45.312-314.746S119.261-9.277 44.859 90.15-9.256 330.494 90.171 404.896c79.868 59.766 189.565 59.766 269.434 0l99.477 99.477c12.501 12.501 32.769 12.501 45.269 0 12.501-12.501 12.501-32.769 0-45.269l.001-.043zm-278.635-73.365c-88.366 0-160-71.634-160-160s71.634-160 160-160 160 71.634 160 160c-.094 88.326-71.673 159.906-160 160z"></path>
                          </svg>
                        </button>
                      </InputGroup.Text>
                    </InputGroup>
                  </FORM>
                )}
              </Formik>
            </div> */}
          </div>
          <div className="settlement-tab-sec">
            <Tab.Container id="left-tabs-example" defaultActiveKey="all">
              <Row>
                <Col sm={12}>
                  <div className="settlement-table-sec table-responsive">
                    {withdrawalList.loading ? (
                      [...Array(3)].map((i, key) => (
                        <Skeleton key={key} height={50} />
                      ))
                    ) : Object.keys(withdrawalList.data).length > 0 ? (
                      <React.Fragment>
                        <Table>
                          <thead>
                            <tr>
                              <th>{t("settlement_table.si_no")}</th>
                              <th>{t("settlement_table.payment_id")}</th>
                              <th>{t("settlement_table.type")}</th>
                              <th>{t("settlement_table.amount")}</th>
                              <th>{t("settlement_table.to_account")}</th>
                              <th>{t("settlement_table.requested_date")}</th>
                              <th>{t("settlement_table.status")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {withdrawalList.data.user_withdrawals.length >
                              0 ? (
                              withdrawalList.data.user_withdrawals.map(
                                (withdrawal, i) => (
                                  <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{withdrawal.unique_id}</td>
                                    <td>{withdrawal.type_formatted}</td>
                                    <td>{withdrawal.amount}</td>
                                    <td>{withdrawal.type == 1 ? withdrawal.bank_account_number.slice(0, 4) + "......" + withdrawal.bank_account_number.slice(-4) : withdrawal.wallet_address.slice(0, 4) + "......" + withdrawal.wallet_address.slice(-4)}</td>
                                    <td>{withdrawal.created_at}</td>
                                    <td>
                                      <div className="settlement-status-cell">
                                        <div
                                          className={getStatusClass(
                                            withdrawal.status
                                          )}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            fill={getStatusColor(
                                              withdrawal.status
                                            )}
                                            data-name="Layer 1"
                                            viewBox="0 0 24 24"
                                          >
                                            <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0z"></path>
                                          </svg>
                                          {withdrawal.status_formatted}
                                        </div>
                                        {withdrawal.status === 0 && <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip id="tooltip">
                                              <strong>{t("cancel_settlement")}</strong>{" "}
                                            </Tooltip>
                                          }
                                        >
                                          <button className="cancel-settlement-btn" onClick={() => setSettlementCancelModal(withdrawal.id)}>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="#FF6A6A"
                                              viewBox="0 0 24 24"
                                              stroke-width="1.5"
                                              stroke="currentColor"
                                              class="w-6 h-6"
                                            >
                                              <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                              />
                                            </svg>
                                          </button>
                                        </OverlayTrigger>}
                                      </div>
                                    </td>
                                  </tr>
                                )
                              )
                            ) : (
                              <td colSpan="6" className="text-center">
                                <NoDataFound />
                              </td>
                            )}
                          </tbody>
                        </Table>
                        <div className="new-billings-pagination-sec">
                          <Pagination
                            page={page}
                            handlePageClick={handlePageClick}
                            pageCount={Math.ceil(
                              withdrawalList.data.total_user_withdrawals / 12
                            )}
                          />
                        </div>
                      </React.Fragment>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default INRPayoutTransactionsIndex;
