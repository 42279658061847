import React, { useState } from "react";
import {
  Form,
  Button,
  Image,
  Label,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import "./PaymentLayout.css";
import { useSelector } from "react-redux";

const PaymentSuccess = (props) => {

  const transactionView = useSelector((state) => state.payment.transactionView);

  return (
    <>
      <div className="efi-payment-form-wrap">
        <div className="payment-success-wrap">
          <Image
            className="succes-icon"
            src={transactionView.data.payment_page.transaction_initiated_image || window.location.origin + "/img/success-icon.png"}
            type="image/png"
          />
          <p>Payment Initiated...</p>
        </div>
        <div className="efi-form-wrap-bottom">
          <div className="form-wrap-btn">
            <Button
              className="btn-next"
              onClick={() => window.location.assign(transactionView.data.success_url ? transactionView.data.success_url : transactionView.data.callback_url)}>
              Finish
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentSuccess;
