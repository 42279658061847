import React, { useEffect, useState } from "react";
import { Form, Button, Image, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  bankAccountListStart,
  requestPayOutStart,
} from "../../store/slices/WithdrawSlice";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { ButtonLoader } from "../Helper/Loader";
import { useTranslation } from "react-multi-lang";

const RequestPayoutModal = (props) => {
  const dispatch = useDispatch();
  const t = useTranslation("request_payout");
  const profile = useSelector((state) => state.admin.profile);
  const bankAccountList = useSelector(
    (state) => state.withdraw.bankAccountList
  );
  const requestPayOut = useSelector((state) => state.withdraw.requestPayOut);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [skipRender, setSkipRender] = useState(true);

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "0px",
      overflow: "hidden",
      minWidth: "100px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "0!important",
      boxShadow: "none!important",
      height: "45px",
      cursor: "pointer",
      minWidth: "auto",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#50506B80",
      fontSize: "1em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#DDE1E6!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#DDE1E6!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#50506B80",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  const validationSchema =
    props.wallet &&
    Yup.object().shape({
      type: Yup.number()
        .required(t("payment_mode.required"))
        .oneOf([1, 2], t("payment_mode.invalid")),
      bank_id: Yup.string().when("type", {
        is: 1,
        then: Yup.string().required(t("bank_account.required")),
        otherwise: Yup.string(),
      }),
      token_type: Yup.string().when("type", {
        is: 2,
        then: Yup.string().required(t("token_type.required")),
        otherwise: Yup.string(),
      }),
      wallet_address: Yup.string().when("type", {
        is: 2,
        then: Yup.string().required("wallet_address.required"),
        otherwise: Yup.string(),
      }),
      amount: Yup.number()
        .required(t("requested_amount.required"))
        .min(
          parseInt(props.wallet.mininum_withdrawal_amount.slice(2)),
          t("amount_must_be_at_least") + props.wallet.mininum_withdrawal_amount
        )
        .max(
          parseInt(props.wallet.remaining.slice(2)),
          t("amount_must_not_exceed") + props.wallet.remaining
        ),
    });

  const tokenOptions = [
    { label: "ETH", value: "ETH" },
    { label: "USDT", value: "USDT" },
    { label: "USDC", value: "USDC" },
    { label: "BUSD", value: "BUSD" },
  ];

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(bankAccountListStart());
    }
  }, [profile]);

  useEffect(() => {
    if (
      !bankAccountList.loading &&
      Object.keys(bankAccountList.data).length > 0 &&
      bankAccountList.data.bank_accounts.length > 0
    ) {
      let acc = bankAccountList.data.bank_accounts.map((acc) => ({
        label: acc.account_holder_name + " - " + acc.account_number.slice(-4),
        value: acc.id,
      }));
      setBankAccounts(acc);
    }
  }, [bankAccountList]);

  const handleSubmit = (values) => {
    dispatch(
      requestPayOutStart(values.type == 1 ? {
        type: values.type,
        amount: values.amount,
        bank_account_id: values.bank_id,
      }: {
        type: values.type,
        amount: values.amount,
        token_type: values.token_type,
        wallet_address: values.wallet_address,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !requestPayOut.loading &&
      Object.keys(requestPayOut.data).length > 0
    ) {
      props.closeRequestPayoutModal();
    }
    setSkipRender(false);
  }, [requestPayOut]);

  return (
    props.wallet && (
      <>
        <Modal
          className="modal-dialog-center payment-link-created-modal"
          size="md"
          centered
          show={props.requestPayout}
          onHide={props.closeRequestPayoutModal}
          backdrop="static"
        >
          <Modal.Body>
            <Button
              onClick={() => props.closeRequestPayoutModal()}
              className="modal-close"
            >
              <Image
                className="close-icon"
                src={window.location.origin + "/img/close-modal-icon.svg"}
              />
            </Button>
            <div className="request-payout-sec">
              <h3>{t("heading")}</h3>
              <div className="request-payout-amount-box">
                <div className="request-payout-amount-card">
                  <h4>{props.wallet.remaining}</h4>
                  <p>{t("your_current_balance")}</p>
                </div>
                <div className="request-payout-amount-card">
                  <h4>{props.wallet.mininum_withdrawal_amount}</h4>
                  <p>{t("minimum_payout_amount")}</p>
                </div>
              </div>
              <Formik
                initialValues={{
                  type: 1,
                  amount_type: 1,
                  bank_id: "",
                  amount: "",
                  token_type: "",
                  wallet_address: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, setFieldValue, setFieldTouched, errors }) => (
                  <FORM className="bank-account-form">
                    <div className="bank-account-radio-sec mb-3">
                      <Form.Group>
                        <Form.Check
                          type="radio"
                          aria-label="radio 1"
                          label={t("amount")}
                          checked={values.amount_type === 1}
                          onClick={(e) => setFieldValue("amount_type", 1)}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Check
                          type="radio"
                          aria-label="radio 1"
                          label={t("all_earning_currently_available")}
                          checked={values.amount_type === 2}
                          onClick={(e) => {
                            setFieldValue("amount_type", 2);
                            setFieldValue(
                              "amount",
                              parseInt(props.wallet.remaining.slice(2))
                            );
                          }}
                        />
                      </Form.Group>
                    </div>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>{t("requested_amount.label")}</Form.Label>
                      <Field
                        className="form-control"
                        placeholder={t("requested_amount.placeholder")}
                        name="amount"
                        type="number"
                        disabled={values.amount_type === 2}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="amount"
                        className="errorMsg w-100"
                      />
                    </Form.Group>
                    <div className="bank-account-radio-sec mb-3">
                      <Form.Label>{t("payment_mode.heading")}</Form.Label>
                      <Form.Group>
                        <Form.Check
                          type="radio"
                          aria-label="radio 1"
                          label={t("payment_mode.label")}
                          checked={values.type === 1}
                          onClick={(e) => setFieldValue("type", 1)}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Check
                          type="radio"
                          aria-label="radio 1"
                          label="Crypto Wallet"
                          checked={values.type === 2}
                          onClick={(e) => {
                            setFieldValue("type", 2);
                          }}
                        />
                      </Form.Group>
                    </div>
                    {values.type == 1 ? (
                      <Form.Group className="mb-4">
                        <Form.Label>{t("bank_account.label")}</Form.Label>
                        <Select
                          options={bankAccounts}
                          styles={customStyles}
                          isSearchable={false}
                          onChange={(selectedOption) => {
                            setFieldValue("bank_id", selectedOption.value);
                          }}
                          placeholder={<div>{t("bank_account.placeholder")}</div>}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="bank_id"
                          className="errorMsg w-100"
                        />
                      </Form.Group>
                    ) : (
                      <React.Fragment>
                        <Form.Group className="mb-4">
                          <Form.Label>{t("token_type.label")}</Form.Label>
                          <Select
                            options={tokenOptions}
                            styles={customStyles}
                            isSearchable={false}
                            onChange={(selectedOption) => {
                              setFieldValue("token_type", selectedOption.value);
                            }}
                            placeholder={<div>{t("token_type.placeholder")}</div>}
                            name="token_type"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="token_type"
                            className="errorMsg w-100"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("wallet_address.label")}</Form.Label>
                          <Field
                            className="form-control"
                            placeholder={t("wallet_address.placeholder")}
                            name="wallet_address"
                            type="text"
                            disabled={values.amount_type === 2}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="wallet_address"
                            className="errorMsg w-100"
                          />
                        </Form.Group>
                      </React.Fragment>
                    )}
                    {/* <div className="request-payout-payment-method-sec">
                    <h5>Payment:</h5>
                    <div className="request-payout-payment-method-info">
                      <p>NetBanking</p>
                      <Image
                        className="request-payout-payment-bank-img"
                        src={window.location.origin + "/img/hdfc-icon.svg"}
                      />
                    </div>
                  </div> */}
                    <div className="request-payout-payment-method-btn-sec">
                      <Button
                        className="efi-cancel-btn"
                        onClick={() => props.closeRequestPayoutModal()}
                      >
                       {t("cancel")}
                      </Button>
                      <Button
                        type="submit"
                        className="efi-auth-btn"
                        disabled={requestPayOut.buttonDisable}
                      >
                        {requestPayOut.buttonDisable ? (
                          <ButtonLoader />
                        ) : (
                          t("payment_btn.text")
                        )}
                      </Button>
                    </div>
                  </FORM>
                )}
              </Formik>
            </div>
          </Modal.Body>
        </Modal>
      </>
    )
  );
};

export default RequestPayoutModal;
