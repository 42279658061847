import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { transactionListStart } from "../../store/slices/PaymentSlice";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../Helper/NoDataFound"
import { useTranslation } from "react-multi-lang";

const RecentTransaction = (props) => {

  const dispatch = useDispatch();
  const t = useTranslation("dashboard.recent_transaction");
  const profile = useSelector((state) => state.admin.profile);
  const transactionList = useSelector((state) => state.payment.transactionList);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(
        transactionListStart({
          skip: 0,
          take: 6,
        })
      );
    }
  }, [profile]);

  const getStatusClass = (status) => {
    switch (status) {
      case 0:
        return "payment-initiated";
      case 1:
        return "payment-onhold";
      case 2:
        return "payment-paid";
      case 3:
        return "payment-rejected";
      case 4:
        return "payment-cancelled";
      case 5: 
        return "payment-failed";
      default:
        return "payment-initiated";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 0:
        return "#5C9EEB";
      case 1:
        return "#FFCE22";
      case 2:
        return "#197E23";
      case 3:
        return "#818181";
      case 4:
        return "#FF8A00";
      case 5:
        return "#FF6A6A";
      default:
        return "#5C9EEB";
    }
  };


  return (
    <>
      <div className="recent-transaction-sec">
        <h3>Recent Transaction</h3>
        <div className="recent-transaction-table-sec table-responsive">
          {transactionList.loading ? (
            [...Array(3)].map((i, key) => <Skeleton key={key} height={50} />)
          ) : Object.keys(transactionList.data).length > 0 ? (
            <React.Fragment>
              <Table>
                <thead>
                  <tr>
                    <th>{t("si_no")}</th>
                    <th>{t("transaction_id")}</th>
                    <th>{t("type")}</th>
                    <th>{t("mode")}</th>
                    <th>{t("amount")}</th>
                    <th>{t("created_date")}</th>
                    <th>{t("status")}</th>
                  </tr>
                </thead>
                <tbody>
                  {transactionList.data.transactions.length > 0 ? (
                    transactionList.data.transactions.slice(0,6).map((transaction, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{transaction.unique_id}</td>
                        <td>{transaction.payment_type_formatted}</td>
                        <td>{transaction.type_formatted}</td>
                        <td>{transaction.amount}</td>
                        <td>{transaction.created_at}</td>
                        <td>
                        <div className={getStatusClass(transaction.status)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="10"
                            fill={getStatusColor(transaction.status)}
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                          >
                            <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0z"></path>
                          </svg>
                          {transaction.status_formatted}
                        </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center">
                        <NoDataFound />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </React.Fragment>
          ) : null}
          <div></div>
        </div>
      </div>
    </>
  );
};

export default RecentTransaction;
