import React, { useState } from "react";
import {
  Image,
  Button,
  InputGroup,
  Label,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import "./PaymentLayout.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PaymentError = (props) => {

  const navigate = useNavigate();
  const transactionView = useSelector((state) => state.payment.transactionView);

  return (
    <>
      <div className="session-sec-wraped">
        {props.error == "session-expired" ? (
          <>
            <Image
              className="session-icon"
              src={transactionView.data.payment_page.transaction_failed_image || window.location.origin + "/img/session-exp-img.png"}
              type="image/png"
            />
            <h4>Your session has expired</h4>
            <p>
              Your session has expired. Please return to the website and try
              again with other transaction
            </p>
            <Button
              className="btn-next"
              type="submit"
              onClick={() => navigate(-1)}
            >
              Go To Website
            </Button>
          </>
        ) : props.error == "transaction-processed" ? (
          <>
            <Image
              className="session-icon"
              src={transactionView.data.payment_page.transaction_failed_image || window.location.origin + "/img/session-exp-img.png"}
              type="image/png"
            />
            <h4>Transaction Processed</h4>
            <p>
              The transaction is already processed. Please return to the website
              and try again with other transaction
            </p>
            <Button
              className="btn-next"
              type="submit"
              onClick={() => navigate(-1)}
            >
              Go To Website
            </Button>
          </>
        ) : props.error == "payment-failed" ? (
          <>
            <Image
              className="session-icon"
              src={transactionView.data.payment_page.transaction_failed_image || window.location.origin + "/img/session-exp-img.png"}
              type="image/png"
            />
            <h4>Payment Failed</h4>
            <p>
              We're sorry, but your payment could not be processed at this time. Please review your payment details and try again. If the issue persists, contact our customer support for assistance.
            </p>
            <Button
              className="btn-next"
              type="submit"
              onClick={() => Object.keys(transactionView.data).length && transactionView.data.failure_url  > 0 ? navigate(-1) : window.location.assign(transactionView.data.failure_url)}
            >
              Go To Website
            </Button>
          </>
        ) : props.error == "payment-cancelled" ? (
          <>
            <Image
              className="session-icon"
              src={transactionView.data.payment_page.transaction_failed_image || window.location.origin + "/img/session-exp-img.png"}
              type="image/png"
            />
            <h4>Payment Cancelled</h4>
            <p>
              We apologize, but your payment has been canceled at this time. If you have any questions or concerns, please feel free to contact our customer support for assistance.
            </p>
            <Button
              className="btn-next"
              type="submit"
              onClick={() => Object.keys(transactionView.data).length && transactionView.data.cancel_url  > 0 ? navigate(-1) : window.location.assign(transactionView.data.cancel_url)}
            >
              Go To Website
            </Button>
          </>
        ) : (
          <>
            <Image
              className="session-icon"
              src={transactionView.data.payment_page.transaction_failed_image || window.location.origin + "/img/session-exp-img.png"}
              type="image/png"
            />
            <h4>{props.errorMessage ? props.errorMessage : "Invalid Transaction"}</h4>
            <p>
              The transaction details are invalid. Please return to the website
              and try again with other transaction
            </p>
            <Button
              className="btn-next"
              type="submit"
              onClick={() => navigate(-1)}
            >
              Go To Website
            </Button>{" "}
          </>
        )}
      </div>
    </>
  );
};

export default PaymentError;
