import React, { useEffect, useState } from "react";
import { Table, Button, Dropdown, Image, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { invoicePaymentViewStart } from "../../store/slices/PaymentSlice";
import PageLoader from "../Helper/PageLoader";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";
import Skeleton from "react-loading-skeleton";
import DeletePaymentModal from "./DeletePaymentModal";
import { useTranslation } from "react-multi-lang";

const InvoicePaymentLinkDetails = (props) => {

  const t = useTranslation("payment.details")
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const profile = useSelector((state) => state.admin.profile);
  const invoicePaymentView = useSelector((state) => state.payment.invoicePaymentView);
  const [deletePaymentModal, setDeletePaymentModal] = useState(false);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0)
      dispatch(invoicePaymentViewStart({ invoice_link_id: params.paymentId }));
  }, [profile]);

  const getStatusClass = (status) => {
    switch (status) {
      case 0:
        return "payment-initiated";
      case 1:
        return "payment-onhold";
      case 2:
        return "payment-paid";
      case 3:
        return "payment-rejected";
      case 4:
        return "payment-cancelled";
      case 5: 
        return "payment-failed";
      default:
        return "payment-initiated";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 0:
        return "#5C9EEB";
      case 1:
        return "#FFCE22";
      case 2:
        return "#197E23";
      case 3:
        return "#818181";
      case 4:
        return "#FF8A00";
      case 5:
        return "#FF6A6A";
      default:
        return "#5C9EEB";
    }
  };

  return (
    <>
      <div className="payment-link-sec">
        <div className="payment-link-details-box">
          <div className="payment-link-details-header-sec">
            <Button
              className="back-btn"
              onClick={() => navigate("/invoice-payment")}
            >
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="12"
                fill="none"
                viewBox="0 0 8 12"
              >
                <path
                  fill="#090830"
                  fillRule="evenodd"
                  d="M6.063 11.276L.67 6.373a.494.494 0 010-.746L6.063.724a.903.903 0 011.19 0 .718.718 0 010 1.082L2.64 6l4.614 4.194c.328.299.328.783 0 1.082a.903.903 0 01-1.19 0z"
                  clipRule="evenodd"
                ></path>
              </svg> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#979BA2"
                  d="M13.293 5.707L9.828 9.171a4 4 0 000 5.657l3.465 3.465c.63.63 1.707.184 1.707-.707V6.414c0-.89-1.077-1.337-1.707-.707z"
                ></path>
              </svg>
            </Button>
            <h3>{t("invoice_title")}</h3>
          </div>
          {invoicePaymentView.loading ? (
            <div className="payment-link-details-body-sec mt-3">
              <Skeleton height={40}/>
              <Skeleton className="mt-3" height={120}/>
              <div className="payment-link-details-copy-url-box mt-3">
                {[...Array(4)].map((i, key) => (
                  <Skeleton key={key} height={80} />
                ))}
              </div>
              <Row className="mt-3">
                <Col md={6}>
                  <Skeleton height={200}/>
                </Col>
                <Col md={6}>
                  <Skeleton height={200}/>
                </Col>
              </Row>
            </div>
          ) : Object.keys(invoicePaymentView.data).length > 0 ? (
            <div className="payment-link-details-body-sec">
              <div className="payment-link-details-top-sec">
                <div className="payment-link-details-card">
                  <div className="payment-link-details-left-sec">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="none"
                      viewBox="0 0 18 18"
                    >
                      <path
                        fill="#111"
                        stroke="#111"
                        strokeWidth="0.5"
                        d="M10.045 12.668l-2.246 2.115c-1.37 1.254-3.524 1.114-4.813-.312-1.264-1.399-1.229-3.522.08-4.79L5.31 7.566a.687.687 0 00.016-.966.675.675 0 00-.958-.048L2.124 8.667c-1.89 1.78-1.939 4.819-.111 6.788 1.827 1.969 4.84 2.122 6.73.343l2.245-2.115a.688.688 0 000-.966.674.674 0 00-.941-.048h-.002 0zM15.686 2.548a4.882 4.882 0 00-3.341-1.58 4.556 4.556 0 00-3.387 1.233l-2.25 2.115a.687.687 0 00-.016.966c.26.28.69.302.958.05l2.248-2.115a3.257 3.257 0 012.423-.883c1.88.097 3.378 1.713 3.346 3.609a3.33 3.33 0 01-1.037 2.375l-2.246 2.115a.687.687 0 00-.016.966c.26.28.689.303.958.05l2.245-2.114c1.887-1.78 1.938-4.816.115-6.787z"
                      ></path>
                      <path
                        fill="#111"
                        stroke="#111"
                        strokeWidth="0.5"
                        d="M10.442 6.547l-4.13 3.89a.686.686 0 00-.033.964.676.676 0 00.974.05l4.13-3.89a.688.688 0 000-.966.675.675 0 00-.94-.048z"
                      ></path>
                    </svg>
                    <span>
                      {invoicePaymentView.data.invoice_link.api_url.slice(0, 50)}...
                      {invoicePaymentView.data.invoice_link.api_url.slice(-30)}
                    </span>
                  </div>
                  <div className="payment-link-details-right-sec">
                    <CopyToClipboard
                      text={invoicePaymentView.data.invoice_link.api_url}
                      onCopy={() =>
                        getSuccessNotificationMessage(
                          t("url_copied")
                        )
                      }
                    >
                      <Button className="copy-btn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          fill="#5C9EEB"
                          height="20"
                          viewBox="0 0 24 24"
                        >
                          <path d="M5.452 22h9.096c1.748 0 3.182-1.312 3.406-3h.594A3.456 3.456 0 0022 15.548V5.452A3.456 3.456 0 0018.548 2H9.452A3.456 3.456 0 006 5.452V6h-.548A3.456 3.456 0 002 9.452v9.096A3.456 3.456 0 005.452 22zM8 5.452C8 4.652 8.651 4 9.452 4h9.096c.8 0 1.452.651 1.452 1.452v10.096c0 .8-.651 1.452-1.452 1.452H18V9.452A3.456 3.456 0 0014.548 6H8zm-4 4C4 8.652 4.651 8 5.452 8h9.096c.8 0 1.452.651 1.452 1.452v9.096c0 .8-.651 1.452-1.452 1.452H5.452C4.652 20 4 19.349 4 18.548z"></path>
                        </svg>
                        <span>{t("copy")}</span>
                      </Button>
                    </CopyToClipboard>
                    {/* {invoicePaymentView.data.invoice_link.transaction.status == 0 ? <Button className="edit-btn"
                      onClick={() =>
                        navigate(
                          `/invoice-payment/create/${invoicePaymentView.data.invoice_link.id}`
                        )
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        viewBox="0 0 16 17"
                      >
                        <path
                          stroke="#111"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13.536 6.296L6.112 13.72a2.667 2.667 0 01-1.886.78h-1.56A.666.666 0 012 13.834v-1.56a2.666 2.666 0 01.78-1.886l7.424-7.424a1.6 1.6 0 012.253 0l1.08 1.08a1.6 1.6 0 010 2.252zM9 4.17l3.333 3.334"
                        ></path>
                      </svg>
                      <span>Edit</span>
                    </Button>:null} */}
                    <Button className="delete-btn" onClick={()=> setDeletePaymentModal(invoicePaymentView.data.invoice_link.id)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        viewBox="0 0 19 20"
                      >
                        <g
                          stroke="#FF6A6A"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          clipPath="url(#clip0_43_3597)"
                        >
                          <path d="M16.666 6.333H3.333M15 8.833l-.558 6.134A3.333 3.333 0 0111.108 18h-2.25a3.333 3.333 0 01-3.333-3.033L5 8.833M8.333 10.5V13M11.658 10.5V13M13.75 6.333h-7.5l.417-1.758A2.067 2.067 0 018.708 3h2.584a2.067 2.067 0 012.041 1.575l.417 1.758z"></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_43_3597">
                            <path
                              fill="#fff"
                              d="M0 0H19V19H0z"
                              transform="translate(0 .5)"
                            ></path>
                          </clipPath>
                        </defs>
                      </svg>
                      <span>{t("delete")}</span>
                    </Button>
                  </div>
                </div>
              </div>
              {/* <div className="payment-link-details-middle-sec">
                <div className="payment-link-details-item-sec">
                  <div className="payment-link-details-header-nav-sec">
                    <h5>Parameter</h5>
                    <h5 className="align-item-text-right">Values</h5>
                  </div>
                  <div className="payment-link-details-item-card">
                    <div className="payment-link-details-table-info">
                      <h4>amount</h4>
                      <div className="small-notes-info">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          fill="none"
                          viewBox="0 0 13 13"
                        >
                          <path
                            fill="#979BA2"
                            fillRule="evenodd"
                            d="M13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0zm-7.15 3.575a.65.65 0 101.3 0v-4.55a.65.65 0 10-1.3 0v4.55zm.65-6.5a.65.65 0 110-1.3.65.65 0 010 1.3z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>
                          This parameter specifies the amount the user is initiating payment for.
                        </span>
                      </div>
                    </div>
                    <h4 className="align-item-text-right">10.08</h4>
                  </div>
                  <div className="payment-link-details-item-card">
                    <div className="payment-link-details-table-info">
                      <h4>order_id</h4>
                      <div className="small-notes-info">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          fill="none"
                          viewBox="0 0 13 13"
                        >
                          <path
                            fill="#979BA2"
                            fillRule="evenodd"
                            d="M13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0zm-7.15 3.575a.65.65 0 101.3 0v-4.55a.65.65 0 10-1.3 0v4.55zm.65-6.5a.65.65 0 110-1.3.65.65 0 010 1.3z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>
                          This unique identifier is used to track the payment status. It aids in updating the payment status on your platform.
                        </span>
                      </div>
                    </div>
                    <h4 className="align-item-text-right">
                    12345                    
                    </h4>
                  </div>
                </div>
              </div> */}
              <div className="efi-payment-box">
                <Row>
                  <Col md={12}>
                    <div className="payment-link-details-copy-url-box">
                      {invoicePaymentView.data.invoice_link.callback_url && (
                        <div className="payment-link-details-copy-url-card bg-blue">
                          <div className="payment-link-details-copy-url-left-sec">
                            <div className="payment-link-details-copy-url-icon-sec">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="none"
                                viewBox="0 0 15 13"
                              >
                                <path
                                  stroke="#3584E1"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1.5"
                                  d="M3.364 6.375L2.182 5.149 1 6.375M11.636 6.375l1.182 1.226 1.181-1.226"
                                ></path>
                                <path
                                  stroke="#3584E1"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1.5"
                                  d="M12.82 7.6V6.376a5.658 5.658 0 00-.984-3.179 5.343 5.343 0 00-2.57-2.008 5.137 5.137 0 00-3.217-.104 5.309 5.309 0 00-2.685 1.84M2.182 5.15v1.225c.003 1.14.346 2.25.982 3.179a5.342 5.342 0 002.57 2.008 5.137 5.137 0 003.217.104 5.309 5.309 0 002.685-1.84"
                                ></path>
                              </svg>
                            </div>
                            <div className="payment-link-details-copy-url-info">
                              <h5>{t("callback_url")}</h5>
                              <p>
                                {invoicePaymentView.data.invoice_link.callback_url}
                              </p>
                            </div>
                          </div>
                          <div className="payment-link-details-copy-url-btn">
                            <CopyToClipboard
                              text={invoicePaymentView.data.invoice_link.callback_url}
                              onCopy={() => getSuccessNotificationMessage("Callback URL copied to clipboard!")}>
                              <Button className="copy-btn">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  fill="#5C9EEB"
                                  height="20"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M5.452 22h9.096c1.748 0 3.182-1.312 3.406-3h.594A3.456 3.456 0 0022 15.548V5.452A3.456 3.456 0 0018.548 2H9.452A3.456 3.456 0 006 5.452V6h-.548A3.456 3.456 0 002 9.452v9.096A3.456 3.456 0 005.452 22zM8 5.452C8 4.652 8.651 4 9.452 4h9.096c.8 0 1.452.651 1.452 1.452v10.096c0 .8-.651 1.452-1.452 1.452H18V9.452A3.456 3.456 0 0014.548 6H8zm-4 4C4 8.652 4.651 8 5.452 8h9.096c.8 0 1.452.651 1.452 1.452v9.096c0 .8-.651 1.452-1.452 1.452H5.452C4.652 20 4 19.349 4 18.548z"></path>
                                </svg>
                                <span>{t("copy")}</span>
                              </Button>
                            </CopyToClipboard>
                          </div>
                        </div>
                      )}
                      {invoicePaymentView.data.invoice_link.success_url && (
                        <div className="payment-link-details-success-url-card bg-green">
                          <div className="payment-link-details-success-url-left-sec">
                            <div className="payment-link-details-success-url-icon-sec">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="none"
                                viewBox="0 0 13 14"
                              >
                                <path
                                  stroke="#269868"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2.076"
                                  d="M2.693 7.128l2.155 2.154 5.387-5.386"
                                ></path>
                              </svg>
                            </div>
                            <div className="payment-link-details-success-url-info">
                              <h5>{t("success_url")}</h5>
                              <p>{invoicePaymentView.data.invoice_link.success_url}</p>
                            </div>
                          </div>
                          <div className="payment-link-details-success-url-btn">
                            <CopyToClipboard
                              text={invoicePaymentView.data.invoice_link.success_url}
                              onCopy={() => getSuccessNotificationMessage("Success URL copied to clipboard!")}>
                              <Button className="success-btn">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  fill="#5C9EEB"
                                  height="20"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M5.452 22h9.096c1.748 0 3.182-1.312 3.406-3h.594A3.456 3.456 0 0022 15.548V5.452A3.456 3.456 0 0018.548 2H9.452A3.456 3.456 0 006 5.452V6h-.548A3.456 3.456 0 002 9.452v9.096A3.456 3.456 0 005.452 22zM8 5.452C8 4.652 8.651 4 9.452 4h9.096c.8 0 1.452.651 1.452 1.452v10.096c0 .8-.651 1.452-1.452 1.452H18V9.452A3.456 3.456 0 0014.548 6H8zm-4 4C4 8.652 4.651 8 5.452 8h9.096c.8 0 1.452.651 1.452 1.452v9.096c0 .8-.651 1.452-1.452 1.452H5.452C4.652 20 4 19.349 4 18.548z"></path>
                                </svg>
                                <span>{t("copy")}</span>
                              </Button>
                            </CopyToClipboard>
                          </div>
                        </div>
                      )}
                      {invoicePaymentView.data.invoice_link.cancel_url && (
                        <div className="payment-link-details-cancel-url-card bg-orange">
                          <div className="payment-link-details-cancel-url-left-sec">
                            <div className="payment-link-details-cancel-url-icon-sec">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="none"
                                viewBox="0 0 14 14"
                              >
                                <path
                                  fill="#FF8A00"
                                  stroke="#FF8A00"
                                  strokeWidth="0.5"
                                  d="M11.001 4.176a5.111 5.111 0 011.117 3.199A5.119 5.119 0 017 12.493h0a5.098 5.098 0 01-3.2-1.123l7.201-7.194zM7 2.256h0a5.112 5.112 0 013.2 1.118l-7.195 7.194a5.067 5.067 0 01-1.123-3.193A5.118 5.118 0 017 2.257zM.75 7.376a6.251 6.251 0 0010.67 4.42A6.25 6.25 0 10.75 7.374z"
                                ></path>
                              </svg>
                            </div>
                            <div className="payment-link-details-cancel-url-info">
                              <h5>{t("cancel_url")}</h5>
                              <p>{invoicePaymentView.data.invoice_link.cancel_url}</p>
                            </div>
                          </div>
                          <div className="payment-link-details-cancel-url-btn">
                          <CopyToClipboard
                              text={invoicePaymentView.data.invoice_link.cancel_url}
                              onCopy={() => getSuccessNotificationMessage("Cancel URL copied to clipboard!")}>
                              <Button className="copy-btn">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                fill="#5C9EEB"
                                height="20"
                                viewBox="0 0 24 24"
                              >
                                <path d="M5.452 22h9.096c1.748 0 3.182-1.312 3.406-3h.594A3.456 3.456 0 0022 15.548V5.452A3.456 3.456 0 0018.548 2H9.452A3.456 3.456 0 006 5.452V6h-.548A3.456 3.456 0 002 9.452v9.096A3.456 3.456 0 005.452 22zM8 5.452C8 4.652 8.651 4 9.452 4h9.096c.8 0 1.452.651 1.452 1.452v10.096c0 .8-.651 1.452-1.452 1.452H18V9.452A3.456 3.456 0 0014.548 6H8zm-4 4C4 8.652 4.651 8 5.452 8h9.096c.8 0 1.452.651 1.452 1.452v9.096c0 .8-.651 1.452-1.452 1.452H5.452C4.652 20 4 19.349 4 18.548z"></path>
                              </svg>
                              <span>{t("copy")}</span>
                            </Button>
                            </CopyToClipboard>
                          </div>
                        </div>
                      )}
                      {invoicePaymentView.data.invoice_link.failure_url && (
                        <div className="payment-link-details-failure-url-card bg-red">
                          <div className="payment-link-details-failure-url-left-sec">
                            <div className="payment-link-details-failure-url-icon-sec">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="none"
                                viewBox="0 0 15 15"
                              >
                                <path
                                  stroke="#FF6A6A"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M4.496 4.022l6.023 6.023M10.519 4.022l-6.023 6.023"
                                ></path>
                              </svg>
                            </div>
                            <div className="payment-link-details-failure-url-info">
                              <h5>{t("failure_url")}</h5>
                              <p>{invoicePaymentView.data.invoice_link.failure_url}</p>
                            </div>
                          </div>
                          <div className="payment-link-details-failure-url-btn">
                          <CopyToClipboard
                              text={invoicePaymentView.data.invoice_link.failure_url}
                              onCopy={() => getSuccessNotificationMessage("Failure URL copied to clipboard!")}>
                              <Button className="copy-btn">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                fill="#5C9EEB"
                                height="20"
                                viewBox="0 0 24 24"
                              >
                                <path d="M5.452 22h9.096c1.748 0 3.182-1.312 3.406-3h.594A3.456 3.456 0 0022 15.548V5.452A3.456 3.456 0 0018.548 2H9.452A3.456 3.456 0 006 5.452V6h-.548A3.456 3.456 0 002 9.452v9.096A3.456 3.456 0 005.452 22zM8 5.452C8 4.652 8.651 4 9.452 4h9.096c.8 0 1.452.651 1.452 1.452v10.096c0 .8-.651 1.452-1.452 1.452H18V9.452A3.456 3.456 0 0014.548 6H8zm-4 4C4 8.652 4.651 8 5.452 8h9.096c.8 0 1.452.651 1.452 1.452v9.096c0 .8-.651 1.452-1.452 1.452H5.452C4.652 20 4 19.349 4 18.548z"></path>
                              </svg>
                              <span>{t("copy")}</span>
                            </Button>
                            </CopyToClipboard>
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="efi-payment-url-sec">
                <Row className="g-3">
                  <Col md={6}>
                    <div className="payment-link-details-info-box">
                      <div className="payment-link-details-header-info-card">
                        <p>{t("type")}</p>
                        <h5 className="align-item-text-right">
                          {invoicePaymentView.data.invoice_link.type == 1
                            ? t("pay_in")
                            : t("pay_out")}
                        </h5>
                      </div>
                      <div className="payment-link-details-info-card">
                        <p>{t("name")}</p>
                        <h4 className="align-item-text-right">
                          {invoicePaymentView.data.invoice_link.name}
                        </h4>
                      </div>
                      <div className="payment-link-details-info-card">
                        <p>{t("payment_method")}</p>
                        <h4 className="align-item-text-right">
                          {invoicePaymentView.data.invoice_link.payment_type_formatted}
                        </h4>
                      </div>
                      {invoicePaymentView.data.invoice_link.payment_type == "inr" && (
                      <div className="payment-link-details-info-card">
                        <p>{t("inr_type")}</p>
                        <h4 className="align-item-text-right">
                          {invoicePaymentView.data.invoice_link.inr_type}
                        </h4>
                      </div>
                      )}
                      <div className="payment-link-details-info-card">
                        <p>{t("amount")}</p>
                        <h4 className="align-item-text-right">
                          {invoicePaymentView.data.invoice_link.amount_formatted}
                        </h4>
                      </div>
                      <div className="payment-link-details-info-card">
                        <p>{t("order_id")}</p>
                        <h4 className="align-item-text-right">
                          {invoicePaymentView.data.invoice_link.order_id}
                        </h4>
                      </div>
                      <div className="payment-link-details-info-card">
                        <p>{t("status")}</p>
                        <h4 className="align-item-text-right" style={{display:"flex", justifyContent: "flex-end"}}>
                          <div className={getStatusClass(invoicePaymentView.data.invoice_link.transaction.status)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="10"
                              fill={getStatusColor(invoicePaymentView.data.invoice_link.transaction.status)}
                              data-name="Layer 1"
                              viewBox="0 0 24 24"
                            >
                              <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0z"></path>
                            </svg>
                            {invoicePaymentView.data.invoice_link.transaction.status_formatted}
                          </div>
                        </h4>
                      </div>
                      <div className="payment-link-details-info-card">
                        <p>{t("merchant_commission_percentage")}</p>
                        <h4 className="align-item-text-right">
                          {invoicePaymentView.data.invoice_link.merchant_commission_percentage}
                        </h4>
                      </div>
                      <div className="payment-link-details-info-card">
                        <p>{t("created_date")}</p>
                        <h4 className="align-item-text-right">
                          {invoicePaymentView.data.invoice_link.created_at}
                        </h4>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="payment-link-details-description-box">
                      <h5>{t("description")}</h5>
                      <p>
                        {invoicePaymentView.data.invoice_link.description || "N/A"}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {deletePaymentModal && <DeletePaymentModal
        navigate={true}
        deletePaymentModal={deletePaymentModal}
        closeDeletePaymentModal={() => setDeletePaymentModal(false)}
        setDeletePaymentModal={setDeletePaymentModal}
      />}
    </>
  );
};

export default InvoicePaymentLinkDetails;
