import React, { useEffect, useState } from "react";
import { Form, Button, Image, Modal, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { changePasswordStart } from "../../store/slices/AdminSlice";
import { ButtonLoader } from "../Helper/Loader";
import useLogout from "../../hooks/useLogout";
import { useTranslation } from "react-multi-lang";

const ChangePasswordModal = (props) => {

  const dispatch = useDispatch();
  const t = useTranslation("change_password");
  const changePassword = useSelector((state) => state.admin.changePassword);
  const [skipRender, setSkipRender] = useState(true);
  const {logout} = useLogout();

  const validationSchema = Yup.object().shape({
    old_password: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("password.invalid")).required(t("password.required")),
    password: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("new_password.invalid"))
      .required(t("new_password.required"))
      .when("old_password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().notOneOf(
          [Yup.ref("old_password")],
          t("new_password.new_password_invalid")
        ),
      }),
    password_confirmation: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/,  t("confirm_password.invalid"))
      .required(t("confirm_password.required"))
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          t("same_password_invalid")
        ),
      }),
  });

  const handleSubmit = (values) => {
    dispatch(changePasswordStart(values))
  };

  useEffect(()=> {
    if(!skipRender && !changePassword.loading && Object.keys(changePassword.data).length > 0)
      props.closeChangePasswordModal();

    setSkipRender(false);
  }, [changePassword])

  return (
    <>
      <Modal
        className="modal-dialog-center payment-link-created-modal"
        size="md"
        centered
        show={props.changePassword}
        onHide={props.closeChangePasswordModal}
      >
        <Modal.Body>
          <Button
            onClick={props.closeChangePasswordModal}
            className="modal-close"
          >
            <Image
              className="close-icon"
              src={window.location.origin + "/img/close-modal-icon.svg"}
            />
          </Button>
          <div className="add-bank-account-modal-sec">
          <div className="settlement-top-sec">
            <h3>{t("heading")}</h3>
          </div>
          <Row className="align-items-center">
            <Col md={12}>
                <div className="bank-account-form-sec">
                <Formik
                    initialValues={{
                      old_password: "",
                      password: "",
                      password_confirmation: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ values, setFieldValue }) => (
                      <FORM className="bank-account-form">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("password.label")}</Form.Label>
                          <Field
                            name="old_password"
                            className="form-control"
                            type="password"
                            autoFocus={true}
                            placeholder={t("password.placeholder")}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="old_password"
                            className="errorMsg"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("new_password.label")}</Form.Label>
                          <Field
                            name="password"
                            className="form-control"
                            type="password"
                            placeholder={t("new_password.placeholder")}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="password"
                            className="errorMsg"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("confirm_password.label")}</Form.Label>
                          <Field
                            name="password_confirmation"
                            className="form-control"
                            type="password"
                            placeholder={t("confirm_password.placeholder")}

                          />
                          <ErrorMessage
                            component={"div"}
                            name="password_confirmation"
                            className="errorMsg"
                          />
                        </Form.Group>
                        <div className="request-payout-payment-method-btn-sec">
                          <Button className="efi-cancel-btn" onClick={()=> {
                            setFieldValue("password_confirmation", "")
                            setFieldValue("old_password", "")
                            setFieldValue("password", "")}}>
                            {t("reset")}
                          </Button>
                          <Button className="efi-auth-btn" disabled={changePassword.buttonDisable} type="submit">{changePassword.buttonDisable ? <ButtonLoader/>: t("save changes_btn.text")}</Button>
                        </div>
                      </FORM>
                    )}
                  </Formik>
                </div>
            </Col>
          </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;
