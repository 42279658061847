import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonFailure,
    getCommonSuccess,
    getCommonStart
} from "../utils"

const initialState = {
    inrPayoutTransactionsList: getCommonInitialState(),
    inrPayoutDetails: getCommonInitialState(),
};

const PayoutSlice = createSlice({
    name: "payout",
    initialState,
    reducers: {

        inrPayoutTransactionsListStart: (state) => { state.inrPayoutTransactionsList = getCommonStart()},
        inrPayoutTransactionsListSuccess: (state, action) => { state.inrPayoutTransactionsList = getCommonSuccess(action.payload)},
        inrPayoutTransactionsListFailure: (state, action) => { state.inrPayoutTransactionsList = getCommonFailure(action.payload)},

        inrPayoutDetailsStart: (state) => { state.inrPayoutDetails = getCommonStart()},
        inrPayoutDetailsSuccess: (state, action) => { state.inrPayoutDetails = getCommonSuccess(action.payload)},
        inrPayoutDetailsFailure: (state, action) => { state.inrPayoutDetails = getCommonFailure(action.payload)},
        
    },
});

export const {
    inrPayoutTransactionsListStart,
    inrPayoutTransactionsListSuccess,
    inrPayoutTransactionsListFailure,
    inrPayoutDetailsStart,
    inrPayoutDetailsSuccess,
    inrPayoutDetailsFailure,
} = PayoutSlice.actions;

export default PayoutSlice.reducer;