import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import configuration from "react-global-configuration";
import "./PaymentLayout.css";
import PaymentSuccess from "./PaymentSuccess";
import PaymentError from "./PaymentError";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { transactionViewStart } from "../../store/slices/PaymentSlice";
import Skeleton from "react-loading-skeleton";
import PayIn from "./PayIn";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import PageLoaderFull from "../Helper/PageLoaderFull";

const PaymentLayout = (props) => {
  
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const transactionView = useSelector((state) => state.payment.transactionView);

  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (searchParams.get("transaction_unique_id")) {
      dispatch(
        transactionViewStart({
          transaction_unique_id: searchParams.get("transaction_unique_id"),
        })
      );
    }
  }, []);

  useEffect(() => {
    if (!skipRender && !transactionView.loading && Object.keys(transactionView.data).length > 0 && Object.keys(transactionView.data.payment_page).length > 0) {
      document.documentElement.style.setProperty('--page-background-color', transactionView.data.payment_page.background);
      document.documentElement.style.setProperty('--card-top', transactionView.data.payment_page.card_header_background);
      document.documentElement.style.setProperty('--card-middle', transactionView.data.payment_page.card_body_background);
      document.documentElement.style.setProperty('--btn-primary-color', transactionView.data.payment_page.primary_btn_color);
      document.documentElement.style.setProperty('--btn-primary-color-hover', transactionView.data.payment_page.primary_btn_hover_color);
      document.documentElement.style.setProperty('--btn-secondary-color', transactionView.data.payment_page.secondary_btn_color);
      document.documentElement.style.setProperty('--btn-secondary-color-hover', transactionView.data.payment_page.secondary_btn_hover_color);
      document.documentElement.style.setProperty('--btn-cancel-confirm-color', transactionView.data.payment_page.cancel_confirm_btn_color);
      document.documentElement.style.setProperty('--btn-cancel-confirm-color-hover', transactionView.data.payment_page.cancel_confirm_btn_hover_color);
      document.documentElement.style.setProperty('--text-primary-color', transactionView.data.payment_page.primary_text_color);
      document.documentElement.style.setProperty('--text-secondary-color', transactionView.data.payment_page.secondary_text_color);
      document.documentElement.style.setProperty('--text-tertiary-color', transactionView.data.payment_page.tertiary_text_color);
      document.documentElement.style.setProperty('--text-cancel-color', transactionView.data.payment_page.cancel_text_color);
      document.documentElement.style.setProperty('--background-tertiary-color', transactionView.data.payment_page.tertiary_background_color);
      document.documentElement.style.setProperty('--icon-color', transactionView.data.payment_page.icon_color);
    }
    setSkipRender(false)
  }, [transactionView]);

  /* functionality refresh */

  // useEffect(() => {
  //   const unloadCallback = (event) => {
  //     console.log("reload before")
  //     event.preventDefault();
  //     event.returnValue = "";
  //     return "";
  //   };
  
  //   window.addEventListener("beforeunload", unloadCallback);
  //   return () => window.removeEventListener("beforeunload", unloadCallback);
  // }, []);

  return transactionView.loading ? <>
    <PageLoaderFull/>
  </> : (
    <>
     {(Object.keys(transactionView.data).length > 0 && Object.keys(transactionView.data.payment_page).length > 0)? <div className="payment-flow-page-sec">
        <div className="payment-flow-header-sec">
          <Container>
            <Row>
              <Col md={12}>
                <div className="efi-payment-left-wrapped">
                  <a>
                    <img
                      className="efi-login"
                      src={transactionView.data.payment_page.logo || configuration.get("configData.site_logo")}
                      alt="logo"
                    />
                  </a>
                  {/* <h1>
                    Easy, Fast & Secure <br /> <span>Payment</span>
                  </h1> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="payment-main-wrapped">
          <Container>
            <Row className="align-items-start justify-content-center">
              {/* <Col md={7}>
                <div className="efi-payment-left-wrapped">
                  <a href="https://efimarkets.com">
                    <img
                      className="efi-login"
                      src={configuration.get("configData.site_logo")}
                      alt="logo"
                    />
                  </a>

                  <h1>
                    Easy, Fast & Secure <br /> <span>Payment</span>
                  </h1>
                </div>
              </Col> */}
              <Col md={6}>
                {searchParams.get("transaction_unique_id") ? (
                  transactionView.loading ? (
                    <Skeleton height={500} />
                  ) : Object.keys(transactionView.data).length > 0 ? transactionView.data.transaction.status == 0 ? (
                    <div className="efi-payment-right-wrapped">
                      <PayIn/>
                    </div>
                  ) : [1, 2].includes(transactionView.data.transaction.status) ? (
                    <div className="efi-payment-right-wrapped">
                      <PaymentSuccess />
                    </div>
                  ) : transactionView.data.transaction.status == 4 ? (
                    <div className="efi-payment-right-wrapped">
                      <PaymentError error="payment-cancelled" />
                    </div>
                  ) : (
                    <div className="efi-payment-right-wrapped">
                      <PaymentError error="payment-failed" />
                    </div>
                  ) : (
                    <div className="efi-payment-right-wrapped">
                      <PaymentError error={transactionView.error.error_code == 148 ? 'session-expired' : 'invalid-transaction'} errorMessage={transactionView.error.error} />
                    </div>
                  )
                ) : (
                  <div className="efi-payment-right-wrapped">
                    <PaymentError error="invalid-transaction" />
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </div> : <SomethingWentWrong/>}
    </>
  );
};

export default PaymentLayout;
