import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import { inrPayoutDetailsFailure, inrPayoutDetailsSuccess, inrPayoutTransactionsListFailure, inrPayoutTransactionsListSuccess } from "../slices/PayoutSlice";


function* inrPayoutTransactionsAPI(action) {
  yield getCommonSaga({
    apiUrl: "inr_payout_transactions_list",
    payload: action.payload,
    successNotify: false,
    success: inrPayoutTransactionsListSuccess,
    failure: inrPayoutTransactionsListFailure,
  });
}

function* inrPayoutDetailsAPI(action) {
  yield getCommonSaga({
    apiUrl: "inr_payout_details",
    payload: action.payload,
    successNotify: false,
    success: inrPayoutDetailsSuccess,
    failure: inrPayoutDetailsFailure,
  });
}

export default function* PayoutSaga() {
  yield all([
    yield takeLatest('payout/inrPayoutTransactionsListStart', inrPayoutTransactionsAPI),
    yield takeLatest('payout/inrPayoutDetailsStart', inrPayoutDetailsAPI),
  ]);
}

