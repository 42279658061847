import React, { useContext, useEffect, useRef, useState } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import LandingPageIndex from "../LandingPage/LandingPageIndex";
import MainLayout from "../layouts/MainLayout";
import DashBoardIndex from "../DashBoard/DashBoardIndex";
import {
  AdminLayout,
  AuthLayout,
  EmptyLayout,
} from "../layouts";
import LoginIndex from "../Auth/LoginIndex";
import RegisterIndex from "../Auth/RegisterIndex";
import {
  setTranslations,
  setLanguage,
} from "react-multi-lang";
import en from "../translations/en.json";
import es from "../translations/es.json";
import jp from "../translations/jp.json";
import ForgotPasswordIndex from "../Auth/ForgotPasswordIndex";
import ResetPasswordIndex from "../Auth/ResetPasswordIndex";
import PaymentLinkIndex from "../PaymentLinks/PaymentLinks";
import CreatePaymentLink from "../PaymentLinks/CreatePaymentLink";

// For Payment flow 
import PaymentLayout from "../Payment/PaymentLayout";
import PaymentLinkDetails from "../PaymentLinks/PaymentLinkDetails";
import SettlementIndex from "../Settlement/SettlementIndex";
import TransactionsIndex from "../Transactions/TransactionsIndex";
import PayoutLayout from "../Payment/PayoutLayout";
import BankAccountIndex from "../BankAccount/BankAccountIndex";
import BankAccountCreate from "../BankAccount/BankAccountCreate";
import BankAccountList from "../BankAccount/BankAccountList";
import AccountIndex from "../Account/AccountIndex";
import StyloPayResponse from "../Payment/StyloPayResponse";
import InvoicePaymentLinkIndex from "../InvoicePaymentLinks/InvoicePaymentLinkIndex";
import CreateInvoicePaymentLink from "../InvoicePaymentLinks/CreateInvoicePaymentLink";
import InvoicePaymentLinkDetails from "../InvoicePaymentLinks/InvoicePaymentLinkDetails";
import CustomiseIndex from "../Customise/CustomiseIndex";
import Page404 from "../Helper/Page404";
import InvoiceTransactionsIndex from "../InvoiceTransactions/InvoiceTransactionsIndex";
import APIReferenceIndex from "../APIReference/APIReferenceIndex";
import INRPayoutTransactionsIndex from "../PayoutTransactions/INRPayoutTransactionsIndex";
import INRPayoutAPIIndex from "../PayoutAPI/INRPayoutAPIIndex";

const $ = window.$;
setTranslations({ en, es, jp });

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Layout screenProps={ScreenProps} {...rest}>
    <Component />
  </Layout>
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) =>
  localStorage.getItem("token") || sessionStorage.getItem("token") ? (
    <Layout screenProps={ScreenProps} {...rest}>
      <Component authRoute={true} />
    </Layout>
  ) : (
    <Navigate to={{ pathname: "/login" }} />
  );

const Base = () => {
  const [loading, setLoading] = useState(true);
  const [configLoading, setConfigLoading] = useState(true);

  useEffect(() => {
    let userLanguage = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
    localStorage.setItem("lang", userLanguage);
    setLanguage(userLanguage);
  }, []);

  return (
    <>
      <Helmet>
				<title>{configuration.get("configData.site_name")}</title>
				<link
					rel="icon"
					type="image/png"
					href={configuration.get("configData.site_icon")}
				/>
				<script src={configuration.get("configData.head_scripts")}></script>
			</Helmet>
      <BrowserRouter>
        <Routes>
          <Route
            path={"/login"}
            element={
              <AppRoute
                component={LoginIndex}
                layout={AuthLayout}
              />
            }
          />
          <Route
            path={"/register"}
            element={
              <AppRoute
                component={RegisterIndex}
                layout={AuthLayout}
              />
            }
          />
          <Route
            path={"/forgot-password"}
            element={
              <AppRoute
                component={ForgotPasswordIndex}
                layout={AuthLayout}
              />
            }
          />
          <Route
            path={"/reset-password/:token"}
            element={
              <AppRoute
                component={ResetPasswordIndex}
                layout={AuthLayout}
              />
            }
          />
          {/* <Route
            path={"/"}
            element={
              <PrivateRoute
                component={DashBoardIndex}
                layout={AdminLayout}
              />
            }
          /> */}
          <Route path={"/"}>
            <Route
              index={true}
              element={
                <PrivateRoute
                  component={DashBoardIndex}
                  layout={AdminLayout}
                />
              }
            />
          </Route>
          {/* <Route
            path={"/dashboard"}
            element={
              <PrivateRoute
                component={DashBoardIndex}
                layout={AdminLayout}
              />
            }
          /> */}

          {/* Payment Links */}
          <Route path={"/api-payment"}>
            <Route
              index={true}
              element={
                <PrivateRoute
                  component={PaymentLinkIndex}
                  layout={AdminLayout}
                />
              }
            />
            <Route
              path={"create"}
              element={
                <PrivateRoute
                  component={CreatePaymentLink}
                  layout={AdminLayout}
                />
              }
            />
            <Route
              path={"create/:paymentId"}
              element={
                <PrivateRoute
                  component={CreatePaymentLink}
                  layout={AdminLayout}
                />
              }
            />
            <Route
              path={"show/:paymentId"}
              element={
                <PrivateRoute
                  component={PaymentLinkDetails}
                  layout={AdminLayout}
                />
              }
            />
          </Route>  

          <Route path={"/invoice-payment"}>
            <Route
              index={true}
              element={
                <PrivateRoute
                  component={InvoicePaymentLinkIndex}
                  layout={AdminLayout}
                />
              }
            />
            <Route
              path={"create"}
              element={
                <PrivateRoute
                  component={CreateInvoicePaymentLink}
                  layout={AdminLayout}
                />
              }
            />
            <Route
              path={"create/:paymentId"}
              element={
                <PrivateRoute
                  component={CreateInvoicePaymentLink}
                  layout={AdminLayout}
                />
              }
            />
            <Route
              path={"show/:paymentId"}
              element={
                <PrivateRoute
                  component={InvoicePaymentLinkDetails}
                  layout={AdminLayout}
                />
              }
            />
          </Route>  

          {/* Transactions */}
          <Route path={"/transactions"}>
            <Route
              index={true}
              element={
                <PrivateRoute
                  component={TransactionsIndex}
                  layout={AdminLayout}
                />
              }
            />
          </Route> 

          <Route path={"/invoice-transactions"}>
            <Route
              index={true}
              element={
                <PrivateRoute
                  component={InvoiceTransactionsIndex}
                  layout={AdminLayout}
                />
              }
            />
          </Route> 

          {/* Bank Accounts  */}

          <Route path={"/bank-accounts"}>
            <Route
              index={true}
              element={
                <PrivateRoute
                  component={BankAccountIndex}
                  layout={AdminLayout}
                />
              }
            />
            <Route
              path={"create"}
              index={true}
              element={
                <PrivateRoute
                  component={BankAccountCreate}
                  layout={AdminLayout}
                />
              }
            />
            <Route
              path={"create/:bankAccountId"}
              index={true}
              element={
                <PrivateRoute
                  component={BankAccountCreate}
                  layout={AdminLayout}
                />
              }
            />
          </Route> 

          <Route
              path={"/settlement"}
              element={
                <PrivateRoute
                  component={SettlementIndex}
                  layout={AdminLayout}
                />
              }
            /> 
            <Route
              path={"/account-settings"}
              element={
                <PrivateRoute
                  component={AccountIndex}
                  layout={AdminLayout}
                />
              }
            /> 
          <Route
            path={"/pay_in"}
            element={
              <AppRoute
                component={PaymentLayout}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/pay_out"}
            element={
              <AppRoute
                component={PayoutLayout}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/customise-payment"}
            element={
              <AppRoute
                component={CustomiseIndex}
                layout={AdminLayout}
              />
            }
          />
          <Route
            path={"/:type/:transaction_unique_id"}
            element={
              <AppRoute
                component={StyloPayResponse}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/api-reference"}
            element={
              <AppRoute
                component={APIReferenceIndex}
                layout={AdminLayout}
              />
            }
          />
          <Route
            path={"/payout-api"}
            element={
              <AppRoute
                component={INRPayoutAPIIndex}
                layout={AdminLayout}
              />
            }
          />
          <Route
            path={"/payout-transactions"}
            element={
              <AppRoute
                component={INRPayoutTransactionsIndex}
                layout={AdminLayout}
              />
            }
          />
          <Route
            path={"*"}
            element={
              <AppRoute
                component={Page404}
                layout={MainLayout}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Base;
