import React, { useEffect, useState } from "react";
import { Table, Button, Dropdown, Image, Row, Col } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";
import { useTranslation } from "react-multi-lang";
import { apiUrl } from "../../Environment";

const APIReferenceIndex = (props) => {
  const t = useTranslation("payment.details");
  const paymentUrl = apiUrl+"create_transaction";

  const apiData = [
    {
      label: "name",
      value: "John Doe",
      info: "This parameter species the name of the customer",
      type: "String (required)",
    },
    {
      label: "payment_type",
      value: "inr",
      info: "This parameter species the type of payment",
      type: "Enum [inr, card, crypto] (required)",
    },
    {
      label: "inr_type",
      value: "UPI",
      info: "This parameter species the type of inr payment",
      type: "Enum [UPI, NEFT, IMPS, RTGS] (required if payment_type is inr)",
    },
    {
      label: "merchant_commission_percentage",
      value: "10",
      info: "This parameter species the merchant commission percentage",
      type: "Float (required)",
    },
    {
      label: "order_id",
      value: "12345",
      info: "This parameter species the order id",
      type: "String (required)",
    },
    {
      label: "amount",
      value: "10.08",
      info: "This parameter species the amount",
      type: "Float (required)",
    },
    {
      label: "callback_url",
      value: "{URL}",
      info: "This parameter species the callback url",
      type: "String (required)",
    },
    {
      label: "cancel_url",
      value: "{URL}",
      info: "This parameter species the cancel url",
      type: "String (required)",
    },
    {
      label: "success_url",
      value: "{URL}",
      info: "This parameter species the success url",
      type: "String (required)",
    },
    {
      label: "failure_url",
      value: "{URL}",
      info: "This parameter species the failure url",
      type: "String (required)",
    },
    {
      label: "description",
      value: "This is a test description",
      info: "This parameter species the description",
      type: "String",
    },
  ];

  return (
    <>
      <div className="payment-link-sec">
        <div className="payment-link-details-box">
          <div className="payment-link-details-header-sec">
            <h3>API Reference</h3>
          </div>
          <div className="payment-link-details-body-sec">
            <div className="payment-link-details-top-sec">
              <div className="payment-link-details-card api-reference">
                <div className="payment-link-details-left-sec">
                    
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    fill="none"
                    viewBox="0 0 18 18"
                  >
                    <path
                      fill="#111"
                      stroke="#111"
                      strokeWidth="0.5"
                      d="M10.045 12.668l-2.246 2.115c-1.37 1.254-3.524 1.114-4.813-.312-1.264-1.399-1.229-3.522.08-4.79L5.31 7.566a.687.687 0 00.016-.966.675.675 0 00-.958-.048L2.124 8.667c-1.89 1.78-1.939 4.819-.111 6.788 1.827 1.969 4.84 2.122 6.73.343l2.245-2.115a.688.688 0 000-.966.674.674 0 00-.941-.048h-.002 0zM15.686 2.548a4.882 4.882 0 00-3.341-1.58 4.556 4.556 0 00-3.387 1.233l-2.25 2.115a.687.687 0 00-.016.966c.26.28.69.302.958.05l2.248-2.115a3.257 3.257 0 012.423-.883c1.88.097 3.378 1.713 3.346 3.609a3.33 3.33 0 01-1.037 2.375l-2.246 2.115a.687.687 0 00-.016.966c.26.28.689.303.958.05l2.245-2.114c1.887-1.78 1.938-4.816.115-6.787z"
                    ></path>
                    <path
                      fill="#111"
                      stroke="#111"
                      strokeWidth="0.5"
                      d="M10.442 6.547l-4.13 3.89a.686.686 0 00-.033.964.676.676 0 00.974.05l4.13-3.89a.688.688 0 000-.966.675.675 0 00-.94-.048z"
                    ></path>
                  </svg>
                  <span>
                    <span style={{fontWeight:"500"}}>POST : </span>
                    {paymentUrl}
                  </span>
                </div>
                <div className="payment-link-details-right-sec">
                  <CopyToClipboard
                    text={paymentUrl}
                    onCopy={() =>
                      getSuccessNotificationMessage(t("url_copied"))
                    }
                  >
                    <Button className="copy-btn" style={{width:"100%"}}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        fill="#5C9EEB"
                        height="20"
                        viewBox="0 0 24 24"
                      >
                        <path d="M5.452 22h9.096c1.748 0 3.182-1.312 3.406-3h.594A3.456 3.456 0 0022 15.548V5.452A3.456 3.456 0 0018.548 2H9.452A3.456 3.456 0 006 5.452V6h-.548A3.456 3.456 0 002 9.452v9.096A3.456 3.456 0 005.452 22zM8 5.452C8 4.652 8.651 4 9.452 4h9.096c.8 0 1.452.651 1.452 1.452v10.096c0 .8-.651 1.452-1.452 1.452H18V9.452A3.456 3.456 0 0014.548 6H8zm-4 4C4 8.652 4.651 8 5.452 8h9.096c.8 0 1.452.651 1.452 1.452v9.096c0 .8-.651 1.452-1.452 1.452H5.452C4.652 20 4 19.349 4 18.548z"></path>
                      </svg>
                      <span>{t("copy")}</span>
                    </Button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
            <div className="payment-link-details-middle-sec">
              <div className="payment-link-details-item-sec">
                <div className="payment-link-details-header-nav-sec">
                  <h5>{t("parameter")}</h5>
                  <div style={{ display: "grid", gridTemplateColumns:"repeat(2, 1fr)" }}>
                    <h5>Type</h5>
                    <h5 className="align-item-text-right">{t("values")}</h5>
                  </div>
                </div>
                {apiData.map((item, key) => (
                  <div className="payment-link-details-item-card" key={key}>
                    <div className="payment-link-details-table-info">
                      <h4>{item.label}</h4>
                      <div className="small-notes-info">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          fill="none"
                          viewBox="0 0 13 13"
                        >
                          <path
                            fill="#979BA2"
                            fillRule="evenodd"
                            d="M13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0zm-7.15 3.575a.65.65 0 101.3 0v-4.55a.65.65 0 10-1.3 0v4.55zm.65-6.5a.65.65 0 110-1.3.65.65 0 010 1.3z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>{item.info}</span>
                      </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns:"repeat(2, 1fr)" }}>
                        <p>{item.type}</p>
                        <h4 className="align-item-text-right">{item.value}</h4>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default APIReferenceIndex;
