import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import NoDataFound from "../Helper/NoDataFound";

const LineGraph = (props) => {
  const combinedData = props.payinData.model_data.map((payinValue, index) => ({
    name: props.payinData.months[index],
    Payin: payinValue,
    Payout: props.payoutData.model_data[index],
  }));

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="line-graph-custom-tooltip">
          <h5 className="label">{`${label}`}</h5>
          <div className="tooltip-inner-sec">
            <p className="payin" style={{color: "#FFC94C"}}>
              <span className="payin-label">Payin:</span>
              <span className="payin-value">{` ${payload[0].value}`}</span>
            </p>
            <p className="payout">
              <span className="payout-label">Payout:</span>
              <span className="payout-value">{` ${payload[1].value}`}</span>
            </p>
          </div>
        </div>
      );
    }
  
    return null;
  }

  return (
    <>
      <div className="line-graph-sec">
        <ResponsiveContainer width="100%" height={400}>
          {props.count ? <LineChart
            data={combinedData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
            <Legend />
            <Line type="monotone" dataKey="Payin" stroke="#FFC94C" activeDot={{ r: 8 }} name="Payin" />
            <Line type="monotone" dataKey="Payout" stroke="#1C1C1C" name="Payout" />
          </LineChart> : <NoDataFound/>}
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default LineGraph;
