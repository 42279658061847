import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { loginStart } from "../../store/slices/AdminSlice";
import { ButtonLoader } from "../Helper/Loader";
import Helmet from "react-helmet";
import configuration from "react-global-configuration";
import OTPVerificationModal from "./OTPVerificationModal";

const LoginIndex = () => {
  const t = useTranslation("login");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const login = useSelector((state) => state.admin.login);
  const [skipRender, setSkipRender] = useState(true);
  const [showVerify, setShowVerify] = useState(false);
  const emailVerify = useSelector(state => state.admin.emailVerify);

  const loginSchema = Yup.object().shape({
    email: Yup.string().email(t("email.invalid")).required(t("email.required")),
    password: Yup.string()
      .required(t("password.required"))
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("password.invalid")),
  });

  const handleLogin = (values) => {
    dispatch(loginStart(values));
  };

  useEffect(() => {
    if (!skipRender && !login.buttonDisable && Object.keys(login.data).length > 0) {
      if (login.data.email_status) {
        navigate("/")
      } else {
        setShowVerify(login.data)
      }
    }
    setSkipRender(false);
  }, [login]);

  useEffect(() => {
    if (
      !skipRender &&
      !emailVerify.loading &&
      Object.keys(emailVerify.data).length > 0
    ) {
      navigate('/')
    }
    setSkipRender(false);
  }, [emailVerify]);


  return (
    <>
      <div className="efi-auth-sec">
        <div className="container">
          <div className="header-logo">
            <div className="efi-logo-sec">
                <a href="https://efimarkets.com">
                  <img
                    className="efi-login"
                    src={configuration.get("configData.site_logo")}
                    alt="logo"
                  />
                </a>
            </div>
          </div>
        </div>
        <div className="efi-auth-item">
          <div className="container">
            <div className="row">
              <div className="col-md-7 resp-marg-btm-xs">
              </div>
              <div className="col-md-5">
                <div className="efi-auth-box">
                  <div className="efi-auth-title-sec">{t("message")}</div>
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                      remember: false,
                    }}
                    validationSchema={loginSchema}
                    onSubmit={handleLogin}
                  >
                    {({ setFieldValue, values }) => (
                      <FORM className="efi-auth-form">
                        <div className="mb-3">
                          <label for="exampleInputEmail1" className="form-label">
                            {t("email.label")}
                          </label>
                          <Field
                            className="form-control"
                            placeholder={t("email.placeholder")}
                            type="email"
                            autoFocus={true}
                            name="email"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="email"
                            className="errorMsg"
                          />
                        </div>
                        <div className="mb-3">
                          <label for="exampleInputEmail1" className="form-label">
                            {t("password.label")}
                          </label>
                          <Field
                            className="form-control"
                            placeholder={t("password.placeholder")}
                            type="password"
                            name="password"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="password"
                            className="errorMsg"
                          />
                        </div>
                        <div className="forgot-password-link-flex">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              checked={values.remember}
                              onChange={() =>
                                setFieldValue("remember", !values.remember)
                              }
                              id="flexCheckDefault"
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                            >
                              {t("remember")}
                            </label>
                          </div>
                          <Link
                            to="/forgot-password"
                            className="forgot-password-link"
                          >
                            {t("forgot")}
                          </Link>
                        </div>
                        <div className="efi-ath-btn-sec">
                          <button
                            className="efi-auth-btn"
                            disabled={login.buttonDisable}
                          >
                            {!login.buttonDisable && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <path d="M12 12a6 6 0 10-6-6 6.006 6.006 0 006 6zm0-10a4 4 0 11-4 4 4 4 0 014-4zM12 14a9.01 9.01 0 00-9 9 1 1 0 002 0 7 7 0 0114 0 1 1 0 002 0 9.01 9.01 0 00-9-9z"></path>
                              </svg>
                            )}
                            {login.buttonDisable ? (
                              <ButtonLoader varient="black" />
                            ) : (
                              t("login_btn.text")
                            )}
                          </button>
                        </div>
                      </FORM>
                    )}
                  </Formik>
                  <div className="efi-footer-link-sec">
                    <span>{t("create_account")}</span>
                    <Link to="/register">{t("register")}</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showVerify ?
        <OTPVerificationModal showVerify={showVerify} closeShowVerify={() => setShowVerify(false)} setShowVerify={setShowVerify} />
        : null}
    </>
  );
};

export default LoginIndex;
