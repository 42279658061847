import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";

const initialState = {
    paymentList: getCommonInitialState(),
    paymentStatus: getCommonInitialState(),
    paymentBulkAction: getCommonInitialState(),
    paymentDelete: getCommonInitialState(),
    paymentView: getCommonInitialState(),
    paymentSave: getCommonInitialState(),
    billingAccounts: getCommonInitialState(),
    payInGenerate: getCommonInitialState(),
    payOutGenerate: getCommonInitialState(),
    transactionList: getCommonInitialState(),
    transactionView: getCommonInitialState(),
    transactionUpdate: getCommonInitialState(),
    transactionCancel: getCommonInitialState(),
    transactionStatus: getCommonInitialState(),
    initiateStylopay: getCommonInitialState(),
    upiSave: getCommonInitialState(),
    upiList: getCommonInitialState(),
    upiDelete: getCommonInitialState(),
    invoicePaymentList: getCommonInitialState(),
    invoicePaymentStatus: getCommonInitialState(),
    invoicePaymentBulkAction: getCommonInitialState(),
    invoicePaymentDelete: getCommonInitialState(),
    invoicePaymentView: getCommonInitialState(),
    invoicePaymentSave: getCommonInitialState(),
    customizePaymentView: getCommonInitialState(),
    customizePaymentSave: getCommonInitialState(),
    customizePaymentReset: getCommonInitialState(),
};

const PaymentSlice = createSlice({
    name: "payment",
    initialState,
    reducers: {

        //payment List
        paymentListStart: (state) => { state.paymentList = getCommonStart() },
        paymentListSuccess: (state, action) => { state.paymentList = getCommonSuccess(action.payload) },
        paymentListFailure: (state, action) => { state.paymentList = getCommonFailure(action.payload) },

        billingAccountsStart: (state) => { state.billingAccounts = getCommonStart() },
        billingAccountsSuccess: (state, action) => { state.billingAccounts = getCommonSuccess(action.payload) },
        billingAccountsFailure: (state, action) => { state.billingAccounts = getCommonFailure(action.payload) },

        //Change payment status
        paymentStatusStart: (state, action) => { state.paymentStatus = getCommonStart(action.payload) },
        paymentStatusSuccess: (state, action) => { state.paymentStatus = getCommonSuccess(action.payload) },
        paymentStatusFailure: (state, action) => { state.paymentStatus = getCommonFailure(action.payload) },

        // Bulk Action
        paymentBulkActionStart: (state) => { state.paymentBulkAction = getCommonStart() },
        paymentBulkActionSuccess: (state, action) => { state.paymentBulkAction = getCommonSuccess(action.payload) },
        paymentBulkActionFailure: (state, action) => { state.paymentBulkAction = getCommonFailure(action.payload) },

        // Delete payment
        paymentDeleteStart: (state, action) => { state.paymentDelete = getCommonStart(action.payload) },
        paymentDeleteSuccess: (state, action) => { state.paymentDelete = getCommonSuccess(action.payload) },
        paymentDeleteFailure: (state, action) => { state.paymentDelete = getCommonFailure(action.payload) },

        // View payment
        paymentViewStart: (state) => { state.paymentView = getCommonStart() },
        paymentViewSuccess: (state, action) => { state.paymentView = getCommonSuccess(action.payload) },
        paymentViewFailure: (state, action) => { state.paymentView = getCommonFailure(action.payload) },

        // Save payment
        paymentSaveStart: (state) => { state.paymentSave = getCommonStart() },
        paymentSaveSuccess: (state, action) => { state.paymentSave = getCommonSuccess(action.payload) },
        paymentSaveFailure: (state, action) => { state.paymentSave = getCommonFailure(action.payload) }, 

        // PayIn Generate
        payInGenerateStart: (state, action) => { state.payInGenerate = getCommonStart(action.payload) },
        payInGenerateSuccess: (state, action) => { state.payInGenerate = getCommonSuccess(action.payload) },
        payInGenerateFailure: (state, action) => { state.payInGenerate = getCommonFailure(action.payload) },

        // PayOut Generate
        payOutGenerateStart: (state, action) => { state.payOutGenerate = getCommonStart(action.payload) },
        payOutGenerateSuccess: (state, action) => { state.payOutGenerate = getCommonSuccess(action.payload) },
        payOutGenerateFailure: (state, action) => { state.payOutGenerate = getCommonFailure(action.payload) },

        // Transaction List
        transactionListStart: (state) => { state.transactionList = getCommonStart() },
        transactionListSuccess: (state, action) => { state.transactionList = getCommonSuccess(action.payload) },
        transactionListFailure: (state, action) => { state.transactionList = getCommonFailure(action.payload) },

        // View transaction
        transactionViewStart: (state) => { state.transactionView = getCommonStart() },
        transactionViewSuccess: (state, action) => { state.transactionView = getCommonSuccess(action.payload) },
        transactionViewFailure: (state, action) => { state.transactionView = getCommonFailure(action.payload) },

        // Save transaction
        transactionUpdateStart: (state) => { state.transactionUpdate = getCommonStart() },
        transactionUpdateSuccess: (state, action) => { state.transactionUpdate = getCommonSuccess(action.payload) },
        transactionUpdateFailure: (state, action) => { state.transactionUpdate = getCommonFailure(action.payload) }, 

        // Cancel transaction
        transactionCancelStart: (state) => { state.transactionCancel = getCommonStart() },
        transactionCancelSuccess: (state, action) => { state.transactionCancel = getCommonSuccess(action.payload) },
        transactionCancelFailure: (state, action) => { state.transactionCancel = getCommonFailure(action.payload) }, 

        // Status update transaction
        transactionStatusStart: (state, action) => { state.transactionStatus = getCommonStart(action.payload) },
        transactionStatusSuccess: (state, action) => { state.transactionStatus = getCommonSuccess(action.payload) },
        transactionStatusFailure: (state, action) => { state.transactionStatus = getCommonFailure(action.payload) }, 
         
        // Initiate Stylopay
        initiateStylopayStart: (state) => { state.initiateStylopay = getCommonStart() },
        initiateStylopaySuccess: (state, action) => { state.initiateStylopay = getCommonSuccess(action.payload) },
        initiateStylopayFailure: (state, action) => { state.initiateStylopay = getCommonFailure(action.payload) }, 

        // UPI 
        upiSaveStart: (state) => { state.upiSave = getCommonStart() },
        upiSaveSuccess: (state, action) => { state.upiSave = getCommonSuccess(action.payload) },
        upiSaveFailure: (state, action) => { state.upiSave = getCommonFailure(action.payload) },

        // View transaction
        upiListStart: (state) => { state.upiList = getCommonStart() },
        upiListSuccess: (state, action) => { state.upiList = getCommonSuccess(action.payload) },
        upiListFailure: (state, action) => { state.upiList = getCommonFailure(action.payload) },

        // View transaction
        upiDeleteStart: (state, action) => { state.upiDelete = getCommonStart(action.payload) },
        upiDeleteSuccess: (state, action) => { state.upiDelete = getCommonSuccess(action.payload) },
        upiDeleteFailure: (state, action) => { state.upiDelete = getCommonFailure(action.payload) },

        // Invoice Payment List
        invoicePaymentListStart: (state) => { state.invoicePaymentList = getCommonStart() },
        invoicePaymentListSuccess: (state, action) => { state.invoicePaymentList = getCommonSuccess(action.payload) },
        invoicePaymentListFailure: (state, action) => { state.invoicePaymentList = getCommonFailure(action.payload) },

        //Change payment status
        invoicePaymentStatusStart: (state, action) => { state.invoicePaymentStatus = getCommonStart(action.payload) },
        invoicePaymentStatusSuccess: (state, action) => { state.invoicePaymentStatus = getCommonSuccess(action.payload) },
        invoicePaymentStatusFailure: (state, action) => { state.invoicePaymentStatus = getCommonFailure(action.payload) },

        // Delete payment
        invoicePaymentDeleteStart: (state, action) => { state.invoicePaymentDelete = getCommonStart(action.payload) },
        invoicePaymentDeleteSuccess: (state, action) => { state.invoicePaymentDelete = getCommonSuccess(action.payload) },
        invoicePaymentDeleteFailure: (state, action) => { state.invoicePaymentDelete = getCommonFailure(action.payload) },

        // View payment
        invoicePaymentViewStart: (state) => { state.invoicePaymentView = getCommonStart() },
        invoicePaymentViewSuccess: (state, action) => { state.invoicePaymentView = getCommonSuccess(action.payload) },
        invoicePaymentViewFailure: (state, action) => { state.invoicePaymentView = getCommonFailure(action.payload) },

        // Save payment
        invoicePaymentSaveStart: (state) => { state.invoicePaymentSave = getCommonStart() },
        invoicePaymentSaveSuccess: (state, action) => { state.invoicePaymentSave = getCommonSuccess(action.payload) },
        invoicePaymentSaveFailure: (state, action) => { state.invoicePaymentSave = getCommonFailure(action.payload) },

        // Customize Payment View
        customizePaymentViewStart: (state) => { state.customizePaymentView = getCommonStart() },
        customizePaymentViewSuccess: (state, action) => { state.customizePaymentView = getCommonSuccess(action.payload) },
        customizePaymentViewFailure: (state, action) => { state.customizePaymentView = getCommonFailure(action.payload) },

        // Customize Payment Save
        customizePaymentSaveStart: (state) => { state.customizePaymentSave = getCommonStart() },
        customizePaymentSaveSuccess: (state, action) => { state.customizePaymentSave = getCommonSuccess(action.payload) },
        customizePaymentSaveFailure: (state, action) => { state.customizePaymentSave = getCommonFailure(action.payload) },

        // Customize Payment Reset
        customizePaymentResetStart: (state) => { state.customizePaymentReset = getCommonStart() },
        customizePaymentResetSuccess: (state, action) => { state.customizePaymentReset = getCommonSuccess(action.payload) },
        customizePaymentResetFailure: (state, action) => { state.customizePaymentReset = getCommonFailure(action.payload) },

    }
});


export const {
    paymentListStart,
    paymentListSuccess,
    paymentListFailure,
    paymentStatusStart,
    paymentStatusSuccess,
    paymentStatusFailure,
    paymentBulkActionStart,
    paymentBulkActionSuccess,
    paymentBulkActionFailure,
    paymentDeleteStart,
    paymentDeleteSuccess,
    paymentDeleteFailure,
    paymentViewStart,
    paymentViewSuccess,
    paymentViewFailure,
    paymentSaveStart,
    paymentSaveSuccess,
    paymentSaveFailure,
    billingAccountsStart,
    billingAccountsSuccess,
    billingAccountsFailure,
    payInGenerateFailure,
    payInGenerateStart,
    payInGenerateSuccess,
    payOutGenerateFailure,
    payOutGenerateStart,
    payOutGenerateSuccess,
    transactionListFailure,
    transactionListStart,
    transactionListSuccess,
    transactionViewFailure,
    transactionViewStart,
    transactionViewSuccess,
    transactionUpdateStart,
    transactionUpdateSuccess,
    transactionUpdateFailure,
    transactionCancelStart,
    transactionCancelSuccess,
    transactionCancelFailure,
    transactionStatusFailure,
    transactionStatusStart,
    transactionStatusSuccess,
    initiateStylopayStart,
    initiateStylopaySuccess,
    initiateStylopayFailure,
    upiDeleteFailure,
    upiDeleteStart,
    upiDeleteSuccess,
    upiListFailure,
    upiListStart,
    upiListSuccess,
    upiSaveFailure,
    upiSaveStart,
    upiSaveSuccess,
    invoicePaymentListFailure,
    invoicePaymentListStart,
    invoicePaymentListSuccess,
    invoicePaymentStatusFailure,
    invoicePaymentStatusStart,
    invoicePaymentStatusSuccess,
    invoicePaymentBulkActionFailure,
    invoicePaymentBulkActionStart,
    invoicePaymentBulkActionSuccess,
    invoicePaymentDeleteFailure,
    invoicePaymentDeleteStart,
    invoicePaymentDeleteSuccess,
    invoicePaymentViewFailure,
    invoicePaymentViewStart,
    invoicePaymentViewSuccess,
    invoicePaymentSaveFailure,
    invoicePaymentSaveStart,
    invoicePaymentSaveSuccess,
    customizePaymentViewFailure,
    customizePaymentViewStart,
    customizePaymentViewSuccess,
    customizePaymentSaveFailure,
    customizePaymentSaveStart,
    customizePaymentSaveSuccess,
    customizePaymentResetFailure,
    customizePaymentResetStart,
    customizePaymentResetSuccess,
    
} = PaymentSlice.actions;

export default PaymentSlice.reducer;