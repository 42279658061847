import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Form, Button, InputGroup, Image, Modal, Row, Col } from "react-bootstrap";
import "./PaymentLayout.css";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { initiateStylopayStart } from "../../store/slices/PaymentSlice";
import PaymentCancel from "./PaymentCancel";

const PayInCard = (props) => {
  const dispatch = useDispatch();

  const transactionView = useSelector((state) => state.payment.transactionView);
  const initiateStylopay = useSelector((state) => state.payment.initiateStylopay);

	const [skipRender, setSkipRender] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const validationSchema = Yup.object().shape({
    card_number: Yup.string()
      .required("Card number is required")
      .matches(/^[0-9]{16}$/, "Invalid card"),
    cvv: Yup.string()
      .required("CVV is required")
      .matches(/^[0-9]{3,4}$/, "Invalid CVV"),
    expiry_month: Yup.string()
      .required("Month is required")
      .test("valid-month", "Invalid month", (value) => {
        const intValue = parseInt(value, 10);
        return intValue >= 1 && intValue <= 12;
      })
      .transform((value) => (value.length === 1 ? `0${value}` : value)),
    expiry_year: Yup.number()
      .required("Year is required")
      .min(new Date().getFullYear() % 100, "Invalid")
      .max(99, "Year must be two digits")
      .integer("Year must be a number"),
  });

  const handleSubmit = (values) => {
    dispatch(
      initiateStylopayStart({
        step: 1,
        transaction_unique_id: transactionView.data.transaction.unique_id,
        ...values,
      })
    );
  };

	useEffect(() => {
    if (
      !skipRender &&
      !initiateStylopay.loading &&
      Object.keys(initiateStylopay.data).length > 0 &&
			initiateStylopay.data.redirectURL
    ) {
      localStorage.setItem("transaction_unique_id", transactionView.data.transaction.unique_id);
      props.setPaymentData(initiateStylopay.data);
      window.location.assign(initiateStylopay.data.redirectURL)
    }
    setSkipRender(false);
  }, [initiateStylopay]);

  return (
    <>
      <div className="efi-payment-form-wrap">
        <div className="efi-form-wrap-top">
          <div className="form-heading">
            <a onClick={() => props.setStep(1)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="11"
                fill="none"
                viewBox="0 0 7 11"
              >
                <path
                  fill="#090830"
                  fillRule="evenodd"
                  d="M5.563 10.776L.17 5.873a.494.494 0 010-.746L5.563.224a.903.903 0 011.19 0 .718.718 0 010 1.082L2.14 5.5l4.614 4.194c.328.299.328.783 0 1.082a.903.903 0 01-1.19 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
            <h2>Checkout</h2>
          </div>
        </div>
        <div className="efi-form-wrap-bottom">
          <Formik
            initialValues={{
              card_number: "",
              cvv: "",
              expiry_month: "",
              expiry_year: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <FORM>
                <div className="card-details-form">
                  <p>Card Details</p>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Card Number * </Form.Label>
                    <Field
                      type="number"
                      className="form-control"
                      name="card_number"
                      placeholder="Enter card number"
                    />
                    <ErrorMessage
                      component={"div"}
                      name="card_number"
                      className="text-danger"
                    />
                  </Form.Group>
                  {/* <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Card Holder Name *</Form.Label>
                <Form.Control type="text" placeholder="Eg. Joseph" />
              </Form.Group> */}
                  <Row>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Expiry Month * </Form.Label>
                        <Field
                          type="number"
                          className="form-control"
                          name="expiry_month"
                          placeholder="MM"
                          onChange={(e) => {
                            const value = e.target.value;
                            if (
                              value.length === 1 &&
                              value !== "0" &&
                              value !== "1"
                            ) {
                              setFieldValue("expiry_month", `0${value}`);
                            } else {
                              setFieldValue("expiry_month", value);
                            }
                          }}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="expiry_month"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Expiry Year * </Form.Label>
                        <Field
                          type="number"
                          className="form-control"
                          name="expiry_year"
                          placeholder="YY"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="expiry_year"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>CVV * </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      name="cvv"
                      placeholder="Enter CVV"
                    />
                    <ErrorMessage
                      component={"div"}
                      name="cvv"
                      className="text-danger"
                    />
                  </Form.Group>
                </div>
                <div className="form-wrap-btn">
                  <Button className="btn-cancel" type="button" onClick={handleShow}>
                    Cancel
                  </Button>
                  <Button
                    className="btn-next"
                    type="submit"
										disabled={initiateStylopay.buttonDisable}
                  >
                    {initiateStylopay.buttonDisable ? "Loading" : "Submit"}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
          {show && <PaymentCancel show={show} handleClose={handleClose} />}
        </div>
      </div>
    </>
  );
};

export default PayInCard;
