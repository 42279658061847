import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonFailure,
    getCommonStart,
    getCommonSuccess,
    getCommonInitialState,
} from '../utils'

const initialState = {
    checkPaymentMethod: getCommonInitialState(),
    transactionInitiate: getCommonInitialState(),
};

const traderSlice = createSlice({
    name: "trader",
    initialState,
    reducers: {

        checkPaymentMethodStart: (state, action) => { state.checkPaymentMethod = getCommonStart() },
        checkPaymentMethodSuccess: (state, action) => { state.checkPaymentMethod = getCommonSuccess(action.payload) },
        checkPaymentMethodFailure: (state, action) => { state.checkPaymentMethod = getCommonFailure(action.payload) },
        checkPaymentMethodClear: (state, action) => { state.checkPaymentMethod = getCommonInitialState() },

        transactionInitiateStart: (state, action) => { state.transactionInitiate = getCommonStart() },
        transactionInitiateSuccess: (state, action) => { state.transactionInitiate = getCommonSuccess(action.payload) },
        transactionInitiateFailure: (state, action) => { state.transactionInitiate = getCommonFailure(action.payload) },

    },
});

export const {
    checkPaymentMethodStart,
    checkPaymentMethodSuccess,
    checkPaymentMethodFailure,
    checkPaymentMethodClear,
    transactionInitiateFailure,
    transactionInitiateStart,
    transactionInitiateSuccess,
} = traderSlice.actions;

export default traderSlice.reducer;