import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getTraderCommonSaga } from "../sagaUtils";
import store from "../index";
import {
  checkPaymentMethodFailure,
  checkPaymentMethodSuccess,
  transactionInitiateFailure,
  transactionInitiateSuccess,
} from "../slices/TraderSlice";

function* checkPaymentMethodAPI(action) {
  yield getTraderCommonSaga({
    apiUrl: "check_payment_method",
    payload: action.payload,
    successNotify: false,
    success: checkPaymentMethodSuccess,
    failure: checkPaymentMethodFailure,
  });
}

function* transactionInitiateAPI(action) {
  yield getTraderCommonSaga({
    apiUrl: "transactions/initiate",
    payload: action.payload,
    successNotify: false,
    success: transactionInitiateSuccess,
    failure: transactionInitiateFailure,
  });
}

export default function* TraderSaga() {
  yield all([
    yield takeLatest("trader/checkPaymentMethodStart", checkPaymentMethodAPI),
    yield takeLatest("trader/transactionInitiateStart", transactionInitiateAPI),
  ]);
}
