import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Dropdown,
  Image,
  Row,
  Tab,
  Nav,
  Col,
  Form,
  InputGroup,
} from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { customizePaymentViewStart, customizePaymentResetStart, customizePaymentSaveStart } from "../../store/slices/PaymentSlice";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import Skeleton from "react-loading-skeleton";
import * as Yup from "yup";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import { ButtonLoader } from "../Helper/Loader";
import ResetModal from "./ResetModal";
import { useTranslation } from "react-multi-lang";

const CustomiseIndex = (props) => {
  const dispatch = useDispatch();
  const t = useTranslation("customise_index");

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
    },
    maxFiles: 1,
    useFsAccessApi: false,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles.length > 0) {
        let reader = new FileReader();
        let file = acceptedFiles[0];
        reader.onloadend = () => {
          formRef.current.setFieldValue("logo_preview", reader.result);
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        formRef.current.setFieldValue("logo", acceptedFiles[0]);
      }
    },
  });

  const {
    getRootProps: getRootPropsCancel,
    getInputProps: getInputPropsCancel,
  } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
    },
    maxFiles: 1,
    useFsAccessApi: false,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles.length > 0) {
        let reader = new FileReader();
        let file = acceptedFiles[0];
        reader.onloadend = () => {
          formRef.current.setFieldValue("cancel_image_preview", reader.result);
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        formRef.current.setFieldValue("cancel_image", acceptedFiles[0]);
      }
    },
  });

  const {
    getRootProps: getRootPropsSuccess,
    getInputProps: getInputPropsSuccess,
  } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
    },
    maxFiles: 1,
    useFsAccessApi: false,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles.length > 0) {
        let reader = new FileReader();
        let file = acceptedFiles[0];
        reader.onloadend = () => {
          formRef.current.setFieldValue(
            "transaction_initiated_image_preview",
            reader.result
          );
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        formRef.current.setFieldValue(
          "transaction_initiated_image",
          acceptedFiles[0]
        );
      }
    },
  });

  const {
    getRootProps: getRootPropsFailure,
    getInputProps: getInputPropsFailure,
  } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
    },
    maxFiles: 1,
    useFsAccessApi: false,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles.length > 0) {
        let reader = new FileReader();
        let file = acceptedFiles[0];
        reader.onloadend = () => {
          formRef.current.setFieldValue(
            "transaction_failed_image_preview",
            reader.result
          );
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        formRef.current.setFieldValue(
          "transaction_failed_image",
          acceptedFiles[0]
        );
      }
    },
  });

  const customizePaymentView = useSelector((state) => state.payment.customizePaymentView);
  const customizePaymentSave = useSelector((state) => state.payment.customizePaymentSave);
  const customizePaymentReset = useSelector((state) => state.payment.customizePaymentReset);
  const [color, setColor] = React.useState({});
  const validationSchema = Yup.object().shape({});
  const [primaryHover, setPrimaryHover] = useState(false);
  const [secondaryHover, setSecondaryHover] = useState(false);
  const [cancelHover, setCancelHover] = useState(false);
  const formRef = useRef(null);
  const [skipRender, setSkipRender] = useState(true);
  const [resetModal, setResetModal] = useState(false);

  const closeResetModal = () => {
    setResetModal(false);
  };

  const handleSubmit = (values) => {
    console.log(values);
  };

  useEffect(() => {
    dispatch(customizePaymentViewStart());
  }, []);

  useEffect(() => {
    if (!skipRender && !customizePaymentReset.loading && Object.keys(customizePaymentView.data).length > 0) {
      closeResetModal();
      dispatch(customizePaymentViewStart());
    }
    setSkipRender(false);
  }, [customizePaymentReset]);



  return (
    <>
      <div className="profile-account-sec">
        <div className="settlement-top-sec">
          <h3>{t("heading")}</h3>
        </div>
        <div className="customise-box">
          {customizePaymentView.loading ? (
            <>
              <Row>
                <Col md={12} xl={6} className="align-items-center">
                  <Skeleton className="mb-3" count={6} height={80} />
                </Col>
                <Col md={12} xl={6}>
                  <Skeleton className="mb-3" height={400} />
                  <Skeleton height={150} />
                </Col>
              </Row>
            </>
          ) : Object.keys(customizePaymentView.data).length > 0 ? (
            <Formik
              initialValues={{
                logo: "",
                logo_preview: customizePaymentView.data.payment_page.logo,
                background: customizePaymentView.data.payment_page.background,
                tertiary_background_color: customizePaymentView.data.payment_page.tertiary_background_color,
                card_header_background:
                  customizePaymentView.data.payment_page.card_header_background,
                card_body_background:
                  customizePaymentView.data.payment_page.card_body_background,
                primary_text_color: customizePaymentView.data.payment_page.primary_text_color,
                secondary_text_color: customizePaymentView.data.payment_page.secondary_text_color,
                tertiary_text_color: customizePaymentView.data.payment_page.tertiary_text_color,
                primary_btn_color:
                  customizePaymentView.data.payment_page.primary_btn_color,
                primary_btn_hover_color:
                  customizePaymentView.data.payment_page
                    .primary_btn_hover_color,
                secondary_btn_color:
                  customizePaymentView.data.payment_page.secondary_btn_color,
                secondary_btn_hover_color:
                  customizePaymentView.data.payment_page
                    .secondary_btn_hover_color,
                icon_color: customizePaymentView.data.payment_page.icon_color,
                cancel_image: "",
                cancel_image_preview:
                  customizePaymentView.data.payment_page.cancel_image,
                cancel_confirm_btn_color:
                  customizePaymentView.data.payment_page
                    .cancel_confirm_btn_color,
                cancel_confirm_btn_hover_color:
                  customizePaymentView.data.payment_page
                    .cancel_confirm_btn_hover_color,
                cancel_text_color: customizePaymentView.data.payment_page.cancel_text_color,
                transaction_initiated_image: "",
                transaction_initiated_image_preview:
                  customizePaymentView.data.payment_page
                    .transaction_initiated_image,
                transaction_failed_image: "",
                transaction_failed_image_preview:
                  customizePaymentView.data.payment_page
                    .transaction_failed_image,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              innerRef={formRef}
            >
              {({ values, setFieldValue }) => (
                <FORM className="customise-account-form">
                  <Row>
                    <Col md={12} xl={6} className="align-items-center">
                      <h4>{t("logo")}</h4>
                      <div className="cutomise-logo-sec">
                        <div
                          {...getRootProps()}
                          className="cutomise-logo-sec-box"
                        >
                          <input {...getInputProps()} />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="35"
                            height="35"
                            fill="none"
                            viewBox="0 0 37 38"
                          >
                            <g
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.525"
                              clipPath="url(#clip0_455_5823)"
                            >
                              <path
                                stroke="#000"
                                strokeOpacity="0.4"
                                d="M24.792 24.871l-6.1-6.099-6.098 6.1"
                              ></path>
                              <path
                                stroke="#252525"
                                d="M18.691 18.772v13.723M31.485 28.515a7.624 7.624 0 00-3.644-14.317H25.92a12.198 12.198 0 10-20.95 11.13"
                              ></path>
                              <path
                                stroke="#252525"
                                d="M24.792 24.871l-6.1-6.099-6.098 6.1"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_455_5823">
                                <path
                                  fill="#fff"
                                  d="M0 0H36.594V36.594H0z"
                                  transform="translate(.396 .475)"
                                ></path>
                              </clipPath>
                            </defs>
                          </svg>
                          <p>{t("upload_the_file_here")}</p>
                        </div>
                        <div className="cutsomise-logo-preview-sec">
                          <CustomLazyLoad
                            className="cutsomise-logo-preview"
                            src={values.logo_preview}
                          />
                          {/* <Button className="delete-btn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill=" #ed3833"
                              viewBox="0 0 24 24"
                            >
                              <path d="M21 4h-3.1A5.009 5.009 0 0013 0h-2a5.009 5.009 0 00-4.9 4H3a1 1 0 000 2h1v13a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5V6h1a1 1 0 000-2zM11 2h2a3.006 3.006 0 012.829 2H8.171A3.006 3.006 0 0111 2zm7 17a3 3 0 01-3 3H9a3 3 0 01-3-3V6h12z"></path>
                              <path d="M10 18a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1zM14 18a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1z"></path>
                            </svg>
                          </Button> */}
                        </div>
                      </div>
                      <h4>{t("failure_popup_image")}</h4>
                      <div className="cutomise-logo-sec">
                        <div
                          {...getRootPropsFailure()}
                          className="cutomise-logo-sec-box"
                        >
                          <input {...getInputPropsFailure()} />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="35"
                            height="35"
                            fill="none"
                            viewBox="0 0 37 38"
                          >
                            <g
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.525"
                              clipPath="url(#clip0_455_5823)"
                            >
                              <path
                                stroke="#000"
                                strokeOpacity="0.4"
                                d="M24.792 24.871l-6.1-6.099-6.098 6.1"
                              ></path>
                              <path
                                stroke="#252525"
                                d="M18.691 18.772v13.723M31.485 28.515a7.624 7.624 0 00-3.644-14.317H25.92a12.198 12.198 0 10-20.95 11.13"
                              ></path>
                              <path
                                stroke="#252525"
                                d="M24.792 24.871l-6.1-6.099-6.098 6.1"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_455_5823">
                                <path
                                  fill="#fff"
                                  d="M0 0H36.594V36.594H0z"
                                  transform="translate(.396 .475)"
                                ></path>
                              </clipPath>
                            </defs>
                          </svg>
                          <p>{t("upload_the_file_here")}</p>
                        </div>
                        <div className="cutsomise-logo-preview-sec">
                          <CustomLazyLoad
                            className="cutsomise-logo-preview"
                            src={values.transaction_failed_image_preview}
                          />
                          {/* <Button className="delete-btn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill=" #ed3833"
                              viewBox="0 0 24 24"
                            >
                              <path d="M21 4h-3.1A5.009 5.009 0 0013 0h-2a5.009 5.009 0 00-4.9 4H3a1 1 0 000 2h1v13a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5V6h1a1 1 0 000-2zM11 2h2a3.006 3.006 0 012.829 2H8.171A3.006 3.006 0 0111 2zm7 17a3 3 0 01-3 3H9a3 3 0 01-3-3V6h12z"></path>
                              <path d="M10 18a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1zM14 18a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1z"></path>
                            </svg>
                          </Button> */}
                        </div>
                      </div>
                      <h4>{t("success_popup_image")}</h4>
                      <div className="cutomise-logo-sec">
                        <div
                          {...getRootPropsSuccess()}
                          className="cutomise-logo-sec-box"
                        >
                          <input {...getInputPropsSuccess()} />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="35"
                            height="35"
                            fill="none"
                            viewBox="0 0 37 38"
                          >
                            <g
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.525"
                              clipPath="url(#clip0_455_5823)"
                            >
                              <path
                                stroke="#000"
                                strokeOpacity="0.4"
                                d="M24.792 24.871l-6.1-6.099-6.098 6.1"
                              ></path>
                              <path
                                stroke="#252525"
                                d="M18.691 18.772v13.723M31.485 28.515a7.624 7.624 0 00-3.644-14.317H25.92a12.198 12.198 0 10-20.95 11.13"
                              ></path>
                              <path
                                stroke="#252525"
                                d="M24.792 24.871l-6.1-6.099-6.098 6.1"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_455_5823">
                                <path
                                  fill="#fff"
                                  d="M0 0H36.594V36.594H0z"
                                  transform="translate(.396 .475)"
                                ></path>
                              </clipPath>
                            </defs>
                          </svg>
                          <p>{t("upload_the_file_here")}</p>
                        </div>
                        <div className="cutsomise-logo-preview-sec">
                          <CustomLazyLoad
                            className="cutsomise-logo-preview"
                            src={values.transaction_initiated_image_preview}
                          />
                          {/* <Button className="delete-btn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill=" #ed3833"
                              viewBox="0 0 24 24"
                            >
                              <path d="M21 4h-3.1A5.009 5.009 0 0013 0h-2a5.009 5.009 0 00-4.9 4H3a1 1 0 000 2h1v13a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5V6h1a1 1 0 000-2zM11 2h2a3.006 3.006 0 012.829 2H8.171A3.006 3.006 0 0111 2zm7 17a3 3 0 01-3 3H9a3 3 0 01-3-3V6h12z"></path>
                              <path d="M10 18a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1zM14 18a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1z"></path>
                            </svg>
                          </Button> */}
                        </div>
                      </div>
                      <h4>{t("cancel_popup_image")}</h4>
                      <div className="cutomise-logo-sec">
                        <div
                          {...getRootPropsCancel()}
                          className="cutomise-logo-sec-box"
                        >
                          <input {...getInputPropsCancel()} />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="35"
                            height="35"
                            fill="none"
                            viewBox="0 0 37 38"
                          >
                            <g
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.525"
                              clipPath="url(#clip0_455_5823)"
                            >
                              <path
                                stroke="#000"
                                strokeOpacity="0.4"
                                d="M24.792 24.871l-6.1-6.099-6.098 6.1"
                              ></path>
                              <path
                                stroke="#252525"
                                d="M18.691 18.772v13.723M31.485 28.515a7.624 7.624 0 00-3.644-14.317H25.92a12.198 12.198 0 10-20.95 11.13"
                              ></path>
                              <path
                                stroke="#252525"
                                d="M24.792 24.871l-6.1-6.099-6.098 6.1"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_455_5823">
                                <path
                                  fill="#fff"
                                  d="M0 0H36.594V36.594H0z"
                                  transform="translate(.396 .475)"
                                ></path>
                              </clipPath>
                            </defs>
                          </svg>
                          <p>{t("upload_the_file_here")}</p>
                        </div>
                        <div className="cutsomise-logo-preview-sec">
                          <CustomLazyLoad
                            className="cutsomise-logo-preview"
                            src={values.cancel_image_preview}
                          />
                          {/* <Button className="delete-btn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill=" #ed3833"
                              viewBox="0 0 24 24"
                            >
                              <path d="M21 4h-3.1A5.009 5.009 0 0013 0h-2a5.009 5.009 0 00-4.9 4H3a1 1 0 000 2h1v13a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5V6h1a1 1 0 000-2zM11 2h2a3.006 3.006 0 012.829 2H8.171A3.006 3.006 0 0111 2zm7 17a3 3 0 01-3 3H9a3 3 0 01-3-3V6h12z"></path>
                              <path d="M10 18a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1zM14 18a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1z"></path>
                            </svg>
                          </Button> */}
                        </div>
                      </div>
                      <div className="customise-item">
                        <h4>{t("background")}</h4>
                        <Row>
                          <Col md={12} xl={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>{t("primary_background_color")}</Form.Label>
                              <div className="customise-flex">
                                <Form.Control
                                  type="text"
                                  placeholder={values.background}
                                  disabled
                                />
                                <div className="customise-color-picker">
                                  <Field
                                    name="background"
                                    type="color"
                                    className="color-form-control"
                                    value={values.background}
                                    onChange={(e) =>
                                      setFieldValue("background", e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={12} xl={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>{t("secondary_background_color")}</Form.Label>
                              <div className="customise-flex">
                                <Form.Control
                                  type="text"
                                  placeholder={values.tertiary_background_color}
                                  disabled
                                />
                                <div className="customise-color-picker">
                                  <Field
                                    name="tertiary_background_color"
                                    type="color"
                                    className="color-form-control"
                                    value={values.tertiary_background_color}
                                    onChange={(e) =>
                                      setFieldValue("tertiary_background_color", e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                      <div className="customise-item">
                        <h4>{t("content")}</h4>
                        <Row>
                          <Col md={12} xl={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>{t("primary_text_color")}</Form.Label>
                              <div className="customise-flex">
                                <Form.Control
                                  placeholder={values.primary_text_color}
                                  disabled
                                />
                                <div className="customise-color-picker">
                                  <Field
                                    name="primary_text_color"
                                    type="color"
                                    className="color-form-control"
                                    value={values.primary_text_color}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "primary_text_color",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>{t("tertiary_text_color")}</Form.Label>
                              <div className="customise-flex">
                                <Form.Control
                                  placeholder={values.tertiary_text_color}
                                  disabled
                                />
                                <div className="customise-color-picker">
                                  <Field
                                    name="tertiary_text_color"
                                    type="color"
                                    className="color-form-control"
                                    value={values.tertiary_text_color}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "tertiary_text_color",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={12} xl={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>{t("secondary_text_color")}</Form.Label>
                              <div className="customise-flex">
                                <Form.Control
                                  placeholder={values.secondary_text_color}
                                  disabled
                                />
                                <div className="customise-color-picker">
                                  <Field
                                    name="card_header_background"
                                    type="color"
                                    className="color-form-control"
                                    value={values.secondary_text_color}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "secondary_text_color",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>{t("icon_text_color")}</Form.Label>
                              <div className="customise-flex">
                                <Form.Control
                                  placeholder={values.icon_color}
                                  disabled
                                />
                                <div className="customise-color-picker">
                                  <Field
                                    name="icon_color"
                                    type="color"
                                    className="color-form-control"
                                    value={values.icon_color}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "icon_color",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                      <div className="customise-item">
                        <h4>{t("card")}</h4>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("header_bckground_color")}</Form.Label>
                          <div className="customise-flex">
                            <Form.Control
                              placeholder={values.card_header_background}
                              disabled
                            />
                            <div className="customise-color-picker">
                              <Field
                                name="card_header_background"
                                type="color"
                                className="color-form-control"
                                value={values.card_header_background}
                                onChange={(e) =>
                                  setFieldValue(
                                    "card_header_background",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("body_background_color")}</Form.Label>
                          <div className="customise-flex">
                            <Form.Control
                              placeholder={values.card_body_background}
                              disabled
                            />
                            <div className="customise-color-picker">
                              <Field
                                name="card_body_background"
                                type="color"
                                className="color-form-control"
                                value={values.card_body_background}
                                onChange={(e) =>
                                  setFieldValue(
                                    "card_body_background",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </div>
                      <div className="customise-item">
                        <h4>{t("button")}</h4>
                        <Row>
                          <Col md={12} xl={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>{t("primary_color")}</Form.Label>
                              <div className="customise-flex">
                                <Form.Control
                                  type="text"
                                  placeholder={values.primary_btn_color}
                                  disabled
                                />
                                <div className="customise-color-picker">
                                  <Field
                                    name="primary_btn_color"
                                    type="color"
                                    className="color-form-control"
                                    value={values.primary_btn_color}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "primary_btn_color",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={12} xl={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>{t("hover_color")}</Form.Label>
                              <div className="customise-flex">
                                <Form.Control
                                  placeholder={values.primary_btn_hover_color}
                                  disabled
                                />
                                <div className="customise-color-picker">
                                  <Field
                                    name="primary_btn_hover_color"
                                    type="color"
                                    className="color-form-control"
                                    value={values.primary_btn_hover_color}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "primary_btn_hover_color",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} xl={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>{t("secondary_color")}</Form.Label>
                              <div className="customise-flex">
                                <Form.Control
                                  type="text"
                                  disabled
                                  placeholder={values.secondary_btn_color}
                                />
                                <div className="customise-color-picker">
                                  <Field
                                    name="secondary_btn_color"
                                    type="color"
                                    className="color-form-control"
                                    value={values.secondary_btn_color}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "secondary_btn_color",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={12} xl={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>{t("hover_color")}</Form.Label>
                              <div className="customise-flex">
                                <Form.Control
                                  type="text"
                                  disabled
                                  placeholder={values.secondary_btn_hover_color}
                                />
                                <div className="customise-color-picker">
                                  <Field
                                    name="secondary_btn_hover_color"
                                    type="color"
                                    className="color-form-control"
                                    value={values.secondary_btn_hover_color}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "secondary_btn_hover_color",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                      <div className="customise-item">
                        <h4>{t("cancel_button")}</h4>
                        <Row>
                          <Col md={12} xl={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>{t("button_color")}</Form.Label>
                              <div className="customise-flex">
                                <Form.Control
                                  type="text"
                                  disabled
                                  placeholder={values.cancel_confirm_btn_color}
                                />
                                <div className="customise-color-picker">
                                  <Field
                                    name="cancel_confirm_btn_color"
                                    type="color"
                                    className="color-form-control"
                                    value={values.cancel_confirm_btn_color}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "cancel_confirm_btn_color",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={12} xl={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>{t("hover_color")}</Form.Label>
                              <div className="customise-flex">
                                <Form.Control
                                  type="text"
                                  placeholder={
                                    values.cancel_confirm_btn_hover_color
                                  }
                                  disabled
                                />
                                <div className="customise-color-picker">
                                  <Field
                                    name="cancel_confirm_btn_hover_color"
                                    type="color"
                                    className="color-form-control"
                                    value={
                                      values.cancel_confirm_btn_hover_color
                                    }
                                    onChange={(e) =>
                                      setFieldValue(
                                        "cancel_confirm_btn_hover_color",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("cancel_txt_color")}</Form.Label>
                          <div className="customise-flex">
                            <Form.Control
                              placeholder={values.cancel_text_color}
                              disabled
                            />
                            <div className="customise-color-picker">
                              <Field
                                name="cancel_text_color"
                                type="color"
                                className="color-form-control"
                                value={values.cancel_text_color}
                                onChange={(e) =>
                                  setFieldValue(
                                    "cancel_text_color",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </div>
                      <div className="customise-btn-sec">
                        <Button className="efi-cancel-btn" onClick={() => setResetModal(true)}>
                          {t("reset")}
                        </Button>
                        <Button type="submit" className="efi-auth-btn" onClick={(e) => dispatch(customizePaymentSaveStart(values))} disabled={customizePaymentReset.buttonDisable || customizePaymentSave.buttonDisable}>
                          {customizePaymentSave.buttonDisable ? (
                            <ButtonLoader />
                          ) : (
                            t("save_btn.text")
                          )
                          }
                        </Button>
                      </div>
                    </Col>
                    <Col md={12} xl={6}>
                      <div className="customise-preview-sec">
                        <div className="card-previw-box">
                          <h4 className="mb-3">{t("preview")}</h4>
                          <div className="efi-payment-right-wrapped">
                            <div
                              className="efi-form-wrap-top"
                              style={{
                                backgroundColor: `${values.card_header_background}`,
                              }}
                            >
                              <div className="form-heading">
                                <h2 style={{
                                  color: `${values.primary_text_color}`,
                                }}>{t("checkout")}</h2>
                              </div>
                              <div className="efi-total-amount-frame">
                                <p style={{
                                  color: `${values.secondary_text_color}`,
                                }}>{t("amount")} :</p>
                                <h3 style={{
                                  color: `${values.primary_text_color}`,
                                }}>100</h3>
                              </div>
                            </div>
                            <div
                              className="efi-form-wrap-middle"
                              style={{
                                backgroundColor: `${values.card_body_background}`,
                                color: `${values.primary_text_color}`,
                              }}
                            >
                              <div className="efi-pay-method-info">
                                <p style={{
                                  color: `${values.secondary_text_color}`,
                                }}>{t("payment_method")}</p>
                                <h4 style={{
                                  color: `${values.primary_text_color}`,
                                }}>{t("card")}</h4>
                              </div>
                              <div className="efi-pay-method-info">
                                <p style={{
                                  color: `${values.secondary_text_color}`,
                                }}>{t("order_id")}</p>
                                <h4 style={{
                                  color: `${values.primary_text_color}`,
                                }}>12345</h4>
                              </div>
                            </div>
                            <div
                              className="efi-form-wrap-bottom"
                              style={{
                                backgroundColor: `${values.card_body_background}`,
                              }}
                            >
                              <div className="form-group-frame">
                                <Form.Label>{t("email_id")}</Form.Label>
                                <InputGroup className="mb-3">
                                  <InputGroup.Text id="email-input" style={{ backgroundColor: "transparent!important", border: `1px solid ${values.primary_text_color}` }}>
                                    <svg style={{ backgroundColor: "transparent!important" }}
                                      width="18"
                                      height="15"
                                      viewBox="0 0 18 15"
                                      fill={values.icon_color}
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g id="Group 1815">
                                        <path
                                          id="Vector"
                                          d="M18 10.7835C18 13.1084 15.984 14.9917 13.473 15H13.464H4.545C2.043 15 0 13.1251 0 10.8001V10.7918C0 10.7918 0.00539994 7.1036 0.0125999 5.24864C0.0134999 4.90032 0.4455 4.70533 0.7398 4.92199C2.8782 6.49278 6.7023 9.35685 6.75 9.39435C7.389 9.8685 8.199 10.136 9.027 10.136C9.855 10.136 10.665 9.8685 11.304 9.38518C11.3517 9.35602 15.0903 6.57778 17.2611 4.98115C17.5563 4.76366 17.9901 4.95865 17.991 5.30614C18 7.14693 18 10.7835 18 10.7835Z"
                                          fill={values.icon_color}
                                        />
                                        <path
                                          id="Vector_2"
                                          d="M17.5283 2.22826C16.7489 0.868303 15.2153 0 13.5269 0H4.5449C2.8565 0 1.3229 0.868303 0.543495 2.22826C0.368895 2.53241 0.451695 2.91157 0.742395 3.12656L7.42491 8.07558C7.89291 8.42556 8.45991 8.59972 9.02691 8.59972H9.03591H9.04491C9.61191 8.59972 10.1789 8.42556 10.6469 8.07558L17.3294 3.12656C17.6201 2.91157 17.7029 2.53241 17.5283 2.22826Z"
                                          fill={values.icon_color}
                                        />
                                      </g>
                                    </svg>
                                  </InputGroup.Text>
                                  <Form.Control
                                    name="email"
                                    type="email"
                                    className="form-control"
                                    placeholder={t("enter_email")}
                                    style={{ border: `1px solid ${values.primary_text_color}` }}
                                  />
                                </InputGroup>
                              </div>
                              <div className="form-wrap-btn">
                                <Button
                                  className="customize-btn-cancel"
                                  style={{
                                    background: secondaryHover
                                      ? `${values.secondary_btn_hover_color}`
                                      : `${values.secondary_btn_color}`,
                                    color: `${values.primary_text_color}`,
                                  }}
                                  onMouseEnter={(e) => setSecondaryHover(true)}
                                  onMouseLeave={(e) => setSecondaryHover(false)}
                                >
                                  {t("cancel")}
                                </Button>
                                <Button
                                  className="customize-btn-next"
                                  type="submit"
                                  style={{
                                    background: primaryHover
                                      ? `${values.primary_btn_hover_color}`
                                      : `${values.primary_btn_color}`,
                                    color: `${values.primary_text_color}`,
                                  }}
                                  onMouseEnter={(e) => setPrimaryHover(true)}
                                  onMouseLeave={(e) => setPrimaryHover(false)}
                                >
                                  {"Next"}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-preview-box">
                          <h4 className="mb-3">{t("cancel_preview")}</h4>
                          <div className="efi-payment-right-wrapped">
                            <div className="payment-cancel-wrapped">
                              <CustomLazyLoad
                                className=""
                                src={values.cancel_image_preview}
                                type="image/png"
                              />
                              <h3>{t("cancel_Payment")}?</h3>
                              <p>{t("are_you_sure_want_to_cancel_this_payment")}?</p>
                              <div className="pay-cancel-btn">
                                <Button
                                  style={{
                                    background: cancelHover
                                      ? `${values.cancel_confirm_btn_hover_color}`
                                      : `${values.cancel_confirm_btn_color}`,
                                    border: "none",
                                    outline: "none",
                                    borderRadius: "0",
                                  }}
                                  onMouseEnter={(e) => setCancelHover(true)}
                                  onMouseLeave={(e) => setCancelHover(false)}
                                >
                                  {t("yes_cancel")}
                                </Button>
                                <Button
                                  className="no-pay"
                                  onClick={props.handleClose}
                                >
                                  {t("no")}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </FORM>
              )}
            </Formik>
          ) : (
            <SomethingWentWrong />
          )}
        </div>
      </div>
      {resetModal && (
        <ResetModal
          resetModal={resetModal}
          closeResetModal={closeResetModal}
          setResetModal={setResetModal}
        />
      )}
    </>
  );
};

export default CustomiseIndex;
