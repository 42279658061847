import React, { useEffect, useState } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { transactionCancelStart } from "../../store/slices/PaymentSlice";

const PaymentCancel = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const transactionView = useSelector((state) => state.payment.transactionView);
  const transactionCancel = useSelector(
    (state) => state.payment.transactionCancel
  );

  const [skiprender, setSkipRender] = useState(true);

  const handleCancel = () => {
    dispatch(
      transactionCancelStart({
        transaction_unique_id: transactionView.data.transaction.unique_id,
      })
    );
  };

  useEffect(() => {
    if (
      !skiprender &&
      !transactionCancel.loading &&
      Object.keys(transactionCancel.data).length > 0
    ) {
      transactionView.data.cancel_url
        ? window.location.assign(transactionView.data.cancel_url)
        : navigate(-1);
    }
    setSkipRender(false);
  }, [transactionCancel]);

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="payment-cancel-wrapped">
            <Image
              className=""
              src={ transactionView.data.payment_page.cancel_image || window.location.origin + "/img/payment-cancel-icon.png"}
              type="image/png"
            />
            <h3>Cancel Payment?</h3>
            <p>Are you sure want to cancel this payment?</p>
            <div className="pay-cancel-btn">
              <Button
                className="cancel-pay"
                onClick={() => handleCancel()}
                disabled={transactionCancel.buttonDisable}
              >
                {transactionCancel.buttonDisable ? "Loading" : "Yes, Cancel"}
              </Button>
              <Button className="no-pay" onClick={props.handleClose}>
                No
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentCancel;
